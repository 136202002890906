#grocerySearchPageMainContainer {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0px);
  max-width: 1400px;
  margin: 30px 0px;
  padding: 0px 20px;
  box-sizing: border-box;
  gap: 15px;
}

#grocerySearchPageAutoFilterBar {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b90504;
  border-radius: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0px 20px;
  box-sizing: border-box;
}

#grocerySearchPageAutoFilterBar h1 {
  color: white;
  margin: 0px 30px;
  font-size: 25px;
  font-family: Montserrat;
  font-weight: 500;
  cursor: pointer;
}

#grocerySearchPageRightContainer {
  position: relative;
  width: 80%;
}

.grocerySearchResultsContainer {
  gap: 15px;
  width: 100%;
  flex-wrap: wrap;
  margin: 15px 0px;
}

/* cards for auto */
.grocerySearchResultCards {
  position: relative;
  background-color: white;
  width: calc(50% - 7.5px);
  max-width: 100%;
  height: 160px;
  border-radius: 20px;
  gap: 10px;
  overflow: hidden;
}

.grocerySearchResultImageDiv {
  height: 100%;
  min-width: 160px;
  max-width: 160px;
  border-right: 2px solid #e8e8e8;
}

.grocerySearchResultImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.grocerySearchResultTextDiv {
  font-family: Montserrat;
  box-sizing: border-box;
  padding: 8px 10px 8px 0;
}

.grocerySearchResultTextDiv:hover.grocerySearchResultTextDiv h1 {
  color: #b90504;
}

.grocerySearchResultTextDiv h1 {
  font-size: 17px;
  color: black;
  margin-bottom: 5px;
}

.grocerySearchResultTextDiv h2 {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.grocerySearchResultTextDiv h3 {
  font-size: 12px;
  font-weight: 500;
  color: dimgray;
  margin-bottom: 2px;
}

.grocerySearchResultTextDiv p {
  color: black;
  font-size: 15px;
  margin-top: auto;
}

.grocerySearchResultTextDiv span {
  font-weight: 600;
  color: #b90504;
}

.grocerySearchResultAddDiv {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background-color: #b90504;
  color: white;
  font-weight: 900;
  font-size: 25px;
  cursor: pointer;
  overflow: hidden;
}

.grocerySearchResultAddDiv:hover {
  background-color: #990100;
}

.grocerySearchPageQuantityDiv {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #b90504;
  width: 80px;
  height: 30px;
  border-radius: 20px;
  overflow: hidden;
  font-family: Montserrat;
}

.grocerySearchPageQuantityButton {
  width: 33%;
  color: white;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.grocerySearchPageQuantityButton:hover {
  background-color: #990100;
}

.grocerySearchPageQuantity {
  width: 34%;
  color: white;
}

/* cards for manual */
.groceryManualSearchResultCardParentDiv {
  width: calc(50% - 7.5px);
  background-color: #333333;
  border-radius: 15px;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #333333;
}

.groceryManualSearchResultShopHeader {
  color: white;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 24px;
  margin-left: 5px;
}

@media (max-width: 780px) {
  #grocerySearchPageMainContainer {
    min-height: 72vh;
    margin: 10px 0px 0 0;
    padding: 0px;
    gap: 10px;
  }

  #grocerySearchPageAutoFilterBar {
    width: calc(100% - 10px);
    height: 50px;
    border-radius: 15px;
    margin: 0px 5px;
    padding: 0px 10px;
  }

  #grocerySearchPageAutoFilterBar label {
    font-size: 13px;
  }

  #grocerySearchPageAutoFilterBar select {
    font-size: 13px;
  }

  .grocerySearchResultsContainer {
    margin: 0;
    padding: 0px 5px;
    box-sizing: border-box;
    gap: 10px;
  }

  .grocerySearchResultImageDiv {
    min-width: 106px;
    max-width: 106px;
  }

  .grocerySearchResultTextDiv {
    padding: 5px 0 10px 0px;
  }

  .grocerySearchResultTextDiv h1 {
    font-size: 15px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    font-weight: 600;
  }

  .grocerySearchResultTextDiv h2 {
    margin-top: 5px;
    font-size: 16px;
  }

  .grocerySearchResultTextDiv h3 {
    font-size: 10px;
  }

  .groceryManualSearchResultShopHeader {
    font-size: 18px;
  }
}

@media (max-width: 680px) {
  .groceryManualSearchResultCardParentDiv {
    width: 100%;
  }

  .grocerySearchResultCards {
    width: 100%;
  }
}
