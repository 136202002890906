@import url(https://fonts.googleapis.com/css?family=Spartan:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap%27);
* {
  margin: 0;
  padding: 0%;
}

body {
  background-color: #e8e8e8;
  color: black;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.redButton {
  border: none;
  background-color: #b90504;
  height: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 20px;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.redButton:hover.hover {
  background-color: #990100;
}

.overlayDiv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

@-webkit-keyframes overlayFadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

@keyframes overlayFadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

@-webkit-keyframes overlayFadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.85);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes overlayFadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.85);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.animatedInputDivs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.animatedInputLabels {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 20px;
  background-color: rgb(34, 34, 34);
  transition: font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
}

.animatedInputInputs {
  width: 70%;
  height: 40px;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  padding: 0px 15px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.animatedInputInputs:focus {
  outline: none;
  border: 2px solid #b90504;
}

/* main labels */
.animatedInputInputs:not(:-ms-input-placeholder) ~ .animatedInputLabels {
  transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}
.animatedInputInputs:focus ~ .animatedInputLabels,
.animatedInputInputs:not(:placeholder-shown) ~ .animatedInputLabels {
  -webkit-transform: translate(-4px, -20px);
          transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:-ms-input-placeholder).animatedInputInputs:not(:focus)
  ~ .animatedInputLabels {
  color: white;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabels {
  color: white;
}

/* lighter gray labels */
.animatedInputLabelsLighterGray {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 20px;
  background-color: #333333;
  transition: font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
}

.animatedInputInputs:not(:-ms-input-placeholder) ~ .animatedInputLabelsLighterGray {
  transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:focus ~ .animatedInputLabelsLighterGray,
.animatedInputInputs:not(:placeholder-shown) ~ .animatedInputLabelsLighterGray {
  -webkit-transform: translate(-4px, -20px);
          transform: translate(-4px, -20px);
  font-size: 12px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:-ms-input-placeholder).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsLighterGray {
  color: white;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsLighterGray {
  color: white;
}

/* smaller gray labels */
.animatedInputLabelsSmallerLighterGray {
  position: absolute;
  left: 15%;
  font-family: Montserrat;
  font-weight: bold;
  color: dimgray;
  font-size: 13px;
  background-color: #333333;
  transition: font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in;
  transition: transform 200ms ease-in, font-size 200ms ease-in,
    padding 200ms ease-in, color 100ms ease-in, -webkit-transform 200ms ease-in;
}

.animatedInputInputs:not(:-ms-input-placeholder)
  ~ .animatedInputLabelsSmallerLighterGray {
  transform: translate(-4px, -20px);
  font-size: 10px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:focus ~ .animatedInputLabelsSmallerLighterGray,
.animatedInputInputs:not(:placeholder-shown)
  ~ .animatedInputLabelsSmallerLighterGray {
  -webkit-transform: translate(-4px, -20px);
          transform: translate(-4px, -20px);
  font-size: 10px;
  padding: 0px 3px;
  color: #b90504;
}

.animatedInputInputs:not(:-ms-input-placeholder).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsSmallerLighterGray {
  color: white;
}

.animatedInputInputs:not(:placeholder-shown).animatedInputInputs:not(:focus)
  ~ .animatedInputLabelsSmallerLighterGray {
  color: white;
}

/* animated box shadow */
.card-boxshadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-boxshadow:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22);
}

/* flexboxes */
.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexJustifyAndAlignCenter {
  justify-content: center;
  align-items: center;
}

.flexJustifyCenter {
  justify-content: center;
}

.flexAlignCenter {
  align-items: center;
}

.flexJustifyRight {
  justify-content: right;
}

.flexJustifyLeft {
  justify-content: left;
}

.flexAlignStart {
  align-items: flex-start;
}

.flexAlignEnd {
  align-items: flex-end;
}

.redBackgroundSelect {
  background-color: #b90504;
  border: none;
  text-align: right;
  padding-right: 0px;
  height: 30px;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.redBackgroundSelect:focus {
  outline: none;
}

.redBackgroundSelect option {
  background-color: white;
  color: black;
}

.redBackgroundSelectLabel {
  color: white;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
}

.groceryTag {
  background-color: green;
  margin: 5px 0px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 10px;
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.textDecorNone {
  text-decoration: none;
}

.text1Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text2Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text3Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* flash card */
.flashCardAlert {
  background-color: rgb(247, 206, 131);
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(224, 173, 77);
  text-align: center;
}

.flashCardSuccess {
  background-color: rgb(181, 253, 196);
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(148, 206, 161);
  text-align: center;
}

.flashCardError {
  background-color: rgb(247, 131, 131);
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid rgb(255, 41, 41);
  text-align: center;
}

.closeButton {
  position: absolute;
  top: -10px;
  right: 10px;
  font-size: 50px;
  color: white;
  font-weight: 900;
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

/* swiper styles */
.swiper-pagination-bullet-active {
  background-color: #b90504 !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: #e8e8e8;
  color: #b90504 !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 25px !important;
  font-weight: 900;
}

@media (max-width: 480px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    background-color: #e8e8e8;
    color: #b90504 !important;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 15px !important;
    font-weight: 900;
  }
}

#homePageMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 100%;
  padding: 0px 25px;
  margin: 25px 0px 0px 0px;
  box-sizing: border-box;
}

#offerSwiperContainerDiv {
  width: 100%;
  grid-gap: 15px;
  gap: 15px;
}

.offerSwiper {
  width: 100%;
  aspect-ratio: 21/7;
  border-radius: 17px;
  margin: 15px 0px;
}

.shopOfferSwiper {
  width: 100%;
  aspect-ratio: 21/8;
  border-radius: 20px;
}

.offerSwiper img {
  width: 100%;
  height: 100%;
}

.shopOfferImageDiv {
  width: 100%;
  height: 100%;
  display: block;
}

.shopOfferImageDiv img {
  width: 100%;
  height: 100%;
}

.shopOfferImageDiv > .componentLoadingDiv img {
  width: 15%;
  aspect-ratio: 1/1;
}

.horizontalSwiper {
  position: relative;
  left: 50%;
  width: calc(100vw - 18px);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 0px 10px 30px 10px;
  margin: 0 0 -20px 0;
  box-sizing: border-box;
}

.homePageProductCards {
  position: relative;
  background-color: white;
  border-radius: 20px;
  font-family: Montserrat;
  padding: 5px;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
  color: black;
}

.homePageProductCards h2 {
  font-size: 16px;
  font-weight: 600;
  height: 34px;
  line-height: 17px;
}

.homePageProductImageDiv {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  aspect-ratio: 1/1;
}

.homePageProductImageDiv img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.homePageProductPriceDiv {
  background-color: #e8e8e8;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 15px;
}

.homePageProductPriceDiv span {
  font-size: 15px;
  font-weight: 500;
  color: #b90504;
  text-decoration: line-through;
}

.homePageProductPriceDiv p {
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  color: #b90504;
}

.componentContainer {
  margin: 0 0 20px 0;
  padding: 15px 0px;
  border-radius: 2vh;
}

.componentContainer h1 {
  font-family: Montserrat;
  margin: -5px 0px 20px 10px;
  font-size: 25px;
  color: #383838;
}

.offersAndShopContainer {
  width: 100%;
  background-color: #333333;
  border-radius: 20px;
  margin: 0px 0px 25px 0px;
  padding: 25px;
  box-sizing: border-box;
}

.offersAndShopContainer h1 {
  color: white;
  font-family: Montserrat;
  margin: 30px 0 20px 10px;
  font-size: 30px;
  text-align: center;
}

.shopNearbyCard {
  aspect-ratio: 16/9;
  border-radius: 20px;
  overflow: hidden;
  background-image: url(https://cdn.discordapp.com/attachments/220769489833164810/909109183117402132/the-sun-music-palm-trees-background-wallpaper-preview.png);
  background-size: 100%;
}

.shopNearbyCardOverlayDiv {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  font-family: Montserrat;
  color: white;
  padding: 10px 15px;
  box-sizing: border-box;
  grid-gap: 5px;
  gap: 5px;
  transition: all 100ms ease-in;
}

.shopNearbyCardOverlayDiv:hover {
  background-color: transparent;
}

.shopNearbyCardOverlayDiv h2 {
  font-size: 26px;
}

.shopNearbyCardOverlayDiv h4 {
  font-size: 20px;
  font-weight: 400;
  margin-top: auto;
}

.shopNearbyCardRatingDiv {
  grid-gap: 10px;
  gap: 10px;
}

.shopNearbyCardRating {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 5px;
  background-color: #b90504;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 15px;
}

.shopNearbyCardDistanceDiv {
  grid-gap: 2px;
  gap: 2px;
  margin-top: 10px;
}

.shopNearbyCardDistanceDiv h5 {
  font-weight: 500;
  font-size: 17px;
}

.homePageCategoryGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  gap: 25px;
}

.homePageCategoryCard {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.homePageCategoryImageDiv {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: white;
  border-radius: 20px;
}

.homePageCategoryImageDiv img {
  width: 100%;
}

.homePageCategoryNameDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: Montserrat;
  background-color: rgba(185, 5, 4, 0.8);
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  color: white;
}

.homePageCategoryNameDiv p {
  font-weight: 600;
}

.homePageNoShopsNearbyDiv {
  position: relative;
  width: 100%;
  aspect-ratio: 21/6;
  color: white;
  background-color: #444444;
  border-radius: 20px;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 800;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}

.homePageNoShopsNearbyDiv > .componentLoadingDiv img {
  width: 15%;
  aspect-ratio: 1/1;
}

@media (max-width: 1023px) {
  #homePageMainContainer {
    margin-top: 0px;
    padding: 0px;
  }

  .offerSwiper {
    border-radius: 10px;
  }

  .shopOfferSwiper {
    border-radius: 10px;
  }

  .homePageProductCards {
    border-radius: 10px;
  }

  .horizontalSwiper {
    width: 100vw;
  }

  .offersAndShopContainer {
    border-radius: 10px;
    padding: 10px 8px;
  }

  .offersAndShopContainer h1 {
    font-size: 17px;
    font-weight: 600;
  }

  .shopNearbyCard {
    border-radius: 10px;
  }

  .homePageCategoryGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .homePageCategoryCard {
    border-radius: 10px;
  }

  .homePageCategoryImageDiv {
    border-radius: 10px;
  }

  .componentContainer {
    padding: 0px;
  }

  .componentContainer h1 {
    font-size: 16px;
    margin: 5px 0px 15px 10px;
  }

  .shopNearbyCardOverlayDiv {
    padding: 5px 7px;
  }

  .shopNearbyCardOverlayDiv h2 {
    font-size: 15px;
  }

  .shopNearbyCardOverlayDiv h4 {
    display: none;
  }

  .shopNearbyCardRatingDiv {
    grid-gap: 5px;
    gap: 5px;
  }

  .shopNearbyCardRating {
    font-size: 10px;
  }

  .shopNearbyCardRatingDiv h3 {
    font-size: 12px;
  }

  .shopNearbyCardDistanceDiv {
    grid-gap: 2px;
    gap: 2px;
    margin-top: auto;
  }

  .shopNearbyCardDistanceDiv h5 {
    font-size: 14px;
  }

  .shopNearbyCardDistanceDiv img {
    height: 14px;
    width: 14px;
  }

  .homePageNoShopsNearbyDiv {
    border-radius: 10px;
  }
}

@media (max-width: 780px) {
  .homePageCategoryGrid {
    grid-gap: 10px;
    gap: 10px;
    padding: 0px 10px;
    box-sizing: border-box;
  }
  .homePageNoShopsNearbyDiv {
    font-size: 22px;
  }

  .homePageProductPriceDiv {
    border-radius: 7px;
  }

  .homePageProductPriceDiv p {
    font-size: 17px;
  }

  .homePageProductPriceDiv span {
    font-size: 15px;
  }

  .homePageProductCards h2 {
    font-size: 14px;
    height: 31px;
    line-height: 15.5px;
  }

  .offerSwiper {
    margin: 0 0 15px 0;
    border-radius: 0;
  }

  .offersAndShopContainer {
    border-radius: 0px;
    padding: 0px 0 15px 0;
  }

  .offersAndShopContainer h1 {
    margin: 15px 10px 10px 10px;
    text-align: center;
    font-size: 18px;
  }

  .shopOfferSwiper {
    border-radius: 0px;
  }

  .shopsNearbySwiper {
    width: 100%;
    padding: 0px 7px;
    box-sizing: border-box;
  }

  .homePageNoShopsNearbyDiv {
    margin: 0px 7px;
    width: calc(100% - 10px);
  }
}

@media (max-width: 759px) {
  .homePageCategoryGrid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 7px;
    gap: 7px;
    padding: 0px 7px;
  }

  .homePageNoShopsNearbyDiv {
    font-size: 15px;
  }

  .homePageProductPriceDiv {
    border-radius: 5px;
  }

  .homePageProductPriceDiv p {
    font-size: 15px;
  }

  .homePageProductPriceDiv span {
    font-size: 11px;
  }

  .homePageProductCards h2 {
    font-size: 10px;
    height: 25px;
    line-height: 12.5px;
  }
}

#navDiv {
  position: relative;
  height: 90px;
  width: 100%;
  background-color: #333333;
  box-shadow: 0px 0px 6px black;
  z-index: 2;
}

#logoImage {
  position: absolute;
  top: 0%;
  left: 56px;
  width: 90px;
  height: 90px;
  z-index: 1;
  cursor: pointer;
}

#topNav {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #333333;
}

#bottomNav {
  position: absolute;
  top: 60px;
  left: 0%;
  width: 100%;
  height: 30px;
  background-color: #403d3d;
}

.bottomNavRightDiv {
  margin: 0px 5px 0px auto;
  height: 100%;
  grid-gap: 30px;
  gap: 30px;
}

.bottomNavRightDiv a {
  text-decoration: none;
}

.bottomNavRightDiv h1 {
  color: white;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
}

.bottomNavRightDiv h1:hover {
  font-weight: 600;
  font-size: 14.5px;
}
.bottomNavLeftDiv {
  height: 100%;
  grid-gap: 30px;
  gap: 30px;
  padding-right: 30px;
  border-right: 2px solid dimgray;
}

.navBarSearchBarDiv {
  position: absolute;
  left: 50%;
  top: 9px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 40%;
  z-index: 2;
  border-radius: 21px;
  overflow: hidden;
  min-height: 42px;
}

.navBarSearchBarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.navBarSearchDropDown {
  width: 100%;
  background-color: white;
}

.navBarSearchTabDiv {
  font-family: Montserrat;
  grid-gap: 10px;
  gap: 10px;
  padding: 31px 10px 10px 10px;
  box-sizing: border-box;
}

.navBarSearchTabs {
  color: dimgray;
  font-weight: bold;
  font-size: 22px;
  height: 34px;
  padding: 0px 10px;
  border-radius: 17px;
  cursor: pointer;
}

.navBarSearchTabs.navBarSearchTabActive {
  background-color: #b90504;
  color: white;
}

.navBarSearchSuggestionsCard {
  background-color: white;
  border-top: 1px solid #e8e8e8;
  font-family: Montserrat;
  padding: 17px 15px;
  box-sizing: border-box;
  cursor: pointer;
  color: black;
}

.navBarSearchSuggestionsCard p {
  font-weight: 500;
}

.navBarSearchSuggestionsCard:hover {
  background-color: #e8e8e8;
}

.navBarSearchNoResultsDiv {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
  color: dimgray;
  padding: 5px;
}

#searchBar {
  position: absolute;
  top: 0px;
  background-color: #222222;
  color: white;
  width: 100%;
  height: 42px;
  border-radius: 21px;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 600;
  padding-left: 15px;
  border: none;
  transition: background-color 200ms ease-in;
  box-sizing: border-box;
  border: 3px solid #222222;
}

#searchBar:focus {
  outline: none;
  border: 3px solid #b90504;
  background-color: white;
  color: black;
}

#searchBar:focus + #searchButton {
  background-color: #b90504;
  -webkit-transform: translateX(-6px);
          transform: translateX(-6px);
}

#searchButton {
  top: 4.5px;
  position: absolute;
  right: 0px;
  -webkit-transform: translateX(-12px);
          transform: translateX(-12px);
  background-color: #222222;
  border-radius: 50%;
  border: none;
  height: 33px;
  width: 33px;
  cursor: pointer;
  border: none;
  transition: all 200ms ease-in;
}

#cartImageDiv {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0px;
  right: 10px;
}

#cartImageDiv:hover {
  background-color: #222222;
}

#infoH3 {
  position: absolute;
  color: white;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-family: Montserrat;
  font-weight: 500;
}

#notSignedInDiv {
  position: absolute;
  top: 0px;
  right: 110px;
  height: 100%;
  cursor: pointer;
  padding: 0px 10px;
}

#notSignedInDiv:hover {
  background-color: #222222;
}

#notSignedInDiv h1 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

#notSignedInDiv h3 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: white;
}

#signedInDiv {
  height: 100%;
  padding-left: 10px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#signedInDiv:hover {
  background-color: #222222;
}

#signedInDiv h1 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: bold;
  color: white;
  margin: -3px 0px;
}

#signedInDiv h3 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: white;
}

#navOrdersDiv {
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
}

#navOrdersDiv h1 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

#navOrdersDiv:hover {
  background-color: #222222;
}

#regionDiv {
  position: absolute;
  left: 20%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 42px;
  background-color: #b90504;
  border-radius: 21px;
  grid-gap: 5px;
  gap: 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  padding-right: 5px;
}

#regionDiv h1 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  color: white;
  margin-bottom: 3px;
  margin-left: 10px;
}

#dropDownDiv::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  right: 34px;
  background-color: white;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

#dropDownDiv {
  position: absolute;
  top: 58px;
  right: 190px;
  background-color: white;
  border-radius: 15px;
  z-index: 4;
  grid-gap: 10px;
  gap: 10px;
  height: 230px;
}

#accountColumnDropDownDiv {
  margin: 10px 10px;
  margin-left: 30px;
  font-family: Montserrat;
}

#accountColumnDropDownDiv a {
  text-decoration: none;
  color: #333333;
}

#accountColumnDropDownDiv h1 {
  font-size: 20px;
  margin-bottom: 5px;
}

#accountColumnDropDownDiv h3 {
  font-size: 17px;
  font-weight: 500;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
}

#accountColumnDropDownDiv h3:hover {
  background-color: #b90504;
  color: white;
}

#accountColumnDropDownDiv h2 {
  position: absolute;
  bottom: 15px;
  font-weight: 520;
  font-size: 22px;
  cursor: pointer;
  padding: 3px 5px;
  border-radius: 5px;
}

#accountColumnDropDownDiv h2:hover {
  background-color: #b90504;
  color: white;
}

#navDropDownOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}

#regionDropDownDiv {
  position: absolute;
  left: 18%;
  top: 60px;
  background-color: white;
  border-radius: 10px;
  width: 64%;
  z-index: 6;
}

#regionDropDownDiv::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50px;
  background-color: white;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

#regionDropDownDiv h1 {
  font-family: Montserrat;
  font-size: 24px;
  color: #333333;
  font-weight: 500;
  margin-top: 25px;
}

#regionDropDownDiv h2 {
  font-family: Montserrat;
  font-size: 12px;
  color: #333333;
  font-weight: 500;
  margin: 15px;
}

#notSignedInRegionDropDownDiv2 {
  width: 85%;
  background-color: #e8e8e8;
  border-radius: 10px;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
}

#notSignedInRegionDropDownDiv2 h1 {
  font-family: Montserrat;
  font-size: 15px;
  color: #333333;
  font-weight: 500;
  margin-top: 10px;
}

#notSignedInRegionDropDownDiv2 button {
  margin-bottom: 15px;
}

#regionSearchBoxParentDiv {
  width: 85%;
  background-color: #e8e8e8;
  border-radius: 20px;
  margin-bottom: 10px;
  padding-right: 6px;
}

#regionSearchBoxDiv {
  position: relative;
  border: 3px solid #b90504;
  border-radius: 20px;
  width: 100%;
  height: 40px;
  background-color: white;
}

#regionSearchBoxDiv input {
  position: relative;
  left: 15px;
  height: 40px;
  width: 75%;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 500;
  border: none;
  background-color: transparent;
}

#regionSearchBoxDiv input:focus {
  outline: none;
}

#regionSearchBoxDiv button {
  position: absolute;
  top: 2.5px;
  right: 0%;
  -webkit-transform: translateX(-3px);
          transform: translateX(-3px);
  height: 35px;
  border-radius: 17.5px;
  grid-gap: 5px;
  gap: 5px;
  padding-left: 10px;
  padding-right: 5px;
}

.regionSearchSuggestionDiv {
  width: 100%;
  border-bottom: 1px solid white;
  padding-right: 6px;
  cursor: pointer;
}

.regionSearchSuggestionDiv h3 {
  margin: 10px 20px;
  font-family: Montserrat;
  font-size: 24px;
  color: #333333;
  font-weight: 500;
}

.regionSearchSuggestionDiv:hover {
  background-color: #d1d1d1;
}

#regionDropDownErrorDiv {
  background-color: #b90504;
  width: 90%;
  margin-bottom: 10px;
}

#regionDropDownErrorDiv h4 {
  color: white;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin: 15px 20px;
}

#signedInWithoutAddressRegionDropDownDiv2 {
  width: 85%;
  background-color: #e8e8e8;
  border-radius: 10px;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
}

#signedInWithoutAddressRegionDropDownDiv2 h1 {
  font-family: Montserrat;
  font-size: 15px;
  color: #333333;
  font-weight: 500;
  margin-top: 10px;
}

#signedInWithAddressRegionDropDownDiv2 {
  position: relative;
  width: 85%;
  background-color: #e8e8e8;
  border-radius: 10px;
  margin-top: 10px;
  grid-gap: 3px;
  gap: 3px;
}

#signedInWithAddressRegionDropDownDiv2 h1 {
  margin: 0px 20px;
  margin-top: 8px;
  font-size: 17px;
  font-weight: 600;
}

#signedInWithAddressRegionDropDownDiv2 h2 {
  margin: 0px 20px;
  font-size: 17px;
  font-weight: 400;
}

/* mobile nav bar start*/

#mobileNavBar {
  position: relative;
  width: 100%;
  background-color: #333333;
  box-shadow: 0px 0px 6px black;
  grid-gap: 5px;
  gap: 5px;
  z-index: 2;
}

#mobileNavFirstDiv {
  position: relative;
  width: 100%;
  height: 35px;
}

#mobileNavFirstDiv div {
  grid-gap: 10px;
  gap: 10px;
}

#mobileNavFirstDiv h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -30%);
          transform: translate(-50%, -30%);
  font-family: Spartan;
  font-size: 24px;
  font-weight: 700;
  color: white;
}

#mobileNavFirstDiv img {
  height: 30px;
  width: 30px;
}

#mobileNavSecondDiv {
  height: 44px;
  width: 100%;
  padding: 0px 4px;
  box-sizing: border-box;
}

#mobileNavThirdDiv {
  background-color: #403d3d;
  height: 30px;
  color: white;
  font-family: Montserrat;
  padding: 0px 10px;
  box-sizing: border-box;
  grid-gap: 5px;
  gap: 5px;
  z-index: 0;
}

#mobileNavThirdDiv h1 {
  font-weight: 500;
  font-size: 18px;
}

.mobileMenuDiv {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #191919;
  width: 280px;
  color: white;
  font-family: Montserrat;
  -webkit-animation-name: menuSlideInLeft;
          animation-name: menuSlideInLeft;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

.mobileMenuSections {
  background-color: #222222;
  padding: 10px 0px;
  margin-top: 7px;
}

.mobileMenuSectionHeader {
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 15px;
}

.mobileMenuLogoDiv {
  background-color: #b90504;
  font-family: Spartan;
  height: 45px;
}

.mobileMenuLogoDiv h1 {
  font-weight: 800;
}

.mobileMenuOptions {
  color: white;
  height: 50px;
  font-size: 20px;
  font-weight: 300;
  padding: 0px 30px;
  box-sizing: border-box;
  background-color: #222222;
}

@-webkit-keyframes menuSlideInLeft {
  from {
    left: -280px;
  }
  to {
    left: 0;
  }
}

@keyframes menuSlideInLeft {
  from {
    left: -280px;
  }
  to {
    left: 0;
  }
}

@-webkit-keyframes menuSlideOutLeft {
  from {
    left: 0px;
  }
  to {
    left: -280px;
  }
}

@keyframes menuSlideOutLeft {
  from {
    left: 0px;
  }
  to {
    left: -280px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  #navOrdersDiv {
    display: none;
  }

  #logoImage {
    left: 0px;
  }

  .navBarSearchBarDiv {
    left: 53%;
  }

  #dropDownDiv {
    right: 70px;
  }
}

@media (max-width: 769px) {
  .navBarSearchBarDiv {
    position: relative;
    left: 0;
    top: 0;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
    width: 100%;
    min-height: 44px;
    overflow: visible;
  }

  #searchBar {
    height: 44px;
    border-radius: 8px;
    background-color: white;
    font-size: 20px;
    border: 3px solid #333333;
  }

  #searchButton {
    height: 35px;
    width: 35px;
    background-color: #b90504;
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    border-radius: 5px;
  }

  #navDropDownOverlay {
    z-index: 2;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
  }

  @-webkit-keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #regionDropDownDiv {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: calc(100vh - 340px);
    width: 100%;
    overflow: scroll;
    border-radius: 10px 10px 0 0;
    z-index: 1;
    -webkit-animation-name: regionPopUp;
            animation-name: regionPopUp;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
  }

  @-webkit-keyframes regionPopUp {
    from {
      top: 100vh;
    }
    to {
      top: calc(100vh - 340px);
    }
  }

  @keyframes regionPopUp {
    from {
      top: 100vh;
    }
    to {
      top: calc(100vh - 340px);
    }
  }

  @-webkit-keyframes regionPopDown {
    from {
      top: calc(100vh - 340px);
    }
    to {
      top: 100vh;
    }
  }

  @keyframes regionPopDown {
    from {
      top: calc(100vh - 340px);
    }
    to {
      top: 100vh;
    }
  }

  #regionSearchBoxDiv input {
    width: 50%;
  }

  #regionDropDownDiv h1 {
    font-size: 20px;
  }

  #regionSearchBoxParentDiv {
    width: 95%;
  }

  #notSignedInRegionDropDownDiv2 {
    width: 95%;
  }

  #notSignedInRegionDropDownDiv2 h1 {
    font-size: 14px;
  }

  #signedInWithoutAddressRegionDropDownDiv2 {
    width: 95%;
  }

  #signedInWithoutAddressRegionDropDownDiv2 h1 {
    font-size: 14px;
  }

  #signedInWithAddressRegionDropDownDiv2 {
    width: 95%;
  }

  #signedInWithAddressRegionDropDownDiv2 h1 {
    font-size: 16px;
  }

  #signedInWithAddressRegionDropDownDiv2 h2 {
    font-size: 16px;
  }

  #regionDropDownDiv::before {
    content: none;
  }

  .regionSearchSuggestionDiv h3 {
    font-size: 20px;
  }
} /* mobile nav bar end */

.componentLoadingDiv {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.cartContainer {
  position: absolute;
  right: 0px;
  top: 0px;
  max-width: 480px;
  width: 100%;
  background-color: #e8e8e8;
  height: 100%;
  box-sizing: border-box;
  -webkit-animation-name: cartSlideInRight;
          animation-name: cartSlideInRight;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

.cartShopContainer {
  padding: 15px 0px;
  width: 100%;
  overflow-y: scroll;
}

.cartTopBar {
  height: 60px;
  background-color: #333333;
  padding: 0px 5px;
  box-sizing: border-box;
}

.cartCloseButton {
  font-size: 50px;
  color: white;
  font-weight: 900;
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.cartShopCard {
  width: calc(100% - 10px);
  border-radius: 10px;
  background-color: #403d3d;
  padding: 10px 0px 0 0px;
  margin: 0px 5px 15px 5px;
  box-sizing: border-box;
  font-family: Montserrat;
}

.cartShopCardHeader {
  color: white;
  font-weight: 500;
  font-size: 24px;
  margin: 0px 15px 10px 15px;
}

.cartProductCard {
  position: relative;
  background-color: white;
  width: 100%;
  height: 130px;
  grid-gap: 10px;
  gap: 10px;
  box-sizing: border-box;
  margin-bottom: 2px;
}

.cartProductCard h1 {
  font-size: 13px;
  font-weight: 500;
  color: black;
}

.cartProductCard h2 {
  font-size: 15px;
  font-weight: 400;
  margin-top: auto;
  color: black;
}
.cartProductCard span {
  font-weight: 500;
  color: #b90504;
}

.cartProductImageDiv {
  min-width: 130px;
  max-width: 130px;
  height: 100%;
  border-right: 2px solid #403d3d;
}

.cartProductImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.cartProductCardTextDiv {
  padding: 10px 0px;
  box-sizing: border-box;
  max-width: calc(100% - 20px - 130px);
  min-width: calc(100% - 30px - 130px);
}

.cartProductCardQuantityDiv {
  background-color: #b90504;
  border-radius: 20px;
  overflow: hidden;
  min-width: 65px;
  max-width: 65px;
  height: 25px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.cartProductCardQuantityButton {
  width: 33%;
  color: white;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.cartProductCardQuantityButton:hover {
  background-color: #990100;
}

.cartProductCardQuantity {
  width: 34%;
  color: white;
}

.cartShopCardBottomDiv {
  height: 60px;
  background-color: #545454;
  border-top: 3px solid #e8e8e8;
  padding: 0px 8px;
  border-radius: 0px 0px 10px 10px;
}

.cartShopCardBottomDiv h2 {
  color: white;
  font-size: 16px;
  font-weight: 300;
}

.cartShopCardBottomDiv span {
  font-weight: 500;
}

.cartShopCardBottomDivCheckout {
  margin-left: auto;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  overflow: hidden;
  height: 30px;
  padding: 0px 10px;
}

.cartShopCardBottomDivCheckout:hover {
  background-color: #990100;
}

.cartRightDivCheckoutDiv {
  background-color: white;
  border-radius: 20px;
  width: 100%;
  padding: 15px 0px 0px 0px;
  position: relative;
  overflow: hidden;
}

.cartRightDivCheckoutDiv h1 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  margin: 0px 15px;
}

.cartRightDivCheckoutDiv h1 span {
  font-weight: 700;
  color: #b90504;
}

.cartRightDivCheckoutSelectedShops {
  width: calc(100% - 30px);
  background-color: #e8e8e8;
  margin: 20px 0px;
  font-family: Montserrat;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 10px;
}

.cartRightDivCheckoutSelectedShops h2 {
  font-size: 15px;
  font-weight: 500;
}

.cartRightDivCheckoutSelectedShops h2 span {
  font-weight: 600;
}

.cartRightDivOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

@-webkit-keyframes cartSlideInRight {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes cartSlideInRight {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes cartSlideOutRight {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes cartSlideOutRight {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@media (min-width: 780px) {
  .cartShopContainer::-webkit-scrollbar {
    width: 8px;
  }

  .cartShopContainer::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 4px;
  }
}

#addAddressMainDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 320px;
  width: 700px;
  border-radius: 10px;
}

#addAddressFormDiv {
  position: relative;
  width: 385px;
  height: 100%;
  background-color: #333333;
  border-radius: 10px 0px 0px 10px;
}

#addAddressFormDiv h1 {
  margin: 20px 0 0 calc(15% - 15px);
  color: white;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 24px;
}

#addAddressMapDiv {
  width: 315px;
  height: 320px;
  background-color: #e8e8e8;
  border-radius: 0px 10px 10px 0px;
  overflow: hidden;
}

#addAddressInactiveInput {
  margin: 20px 0 0 calc(15% - 17px);
  width: 70%;
  height: 40px;
  border-radius: 10px;
  padding: 0px 15px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  color: white;
  border: 2px solid white;
}

#addAddressForm {
  margin: 15px 0 0 0;
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

#addAddressButtonDiv {
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

#addAddressMapButton {
  position: absolute;
  top: 8px;
  right: 45px;
  height: 33px;
  background-color: white;
  border: 2px solid rgb(214, 214, 214);
  border-radius: 5px;
  padding: 0px 5px;
  cursor: pointer;
}

#addAddressMapButton:hover {
  background-color: rgb(247, 247, 247);
}

@media (max-width: 700px) {
  #addAddressMainDiv {
    position: absolute;
    top: auto;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    bottom: 0px;
    max-width: 100%;
    height: calc(100% - 119px);
    flex-direction: column-reverse;
  }

  #addAddressFormDiv {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #333333;
    border-radius: 0;
  }

  #addAddressMapDiv {
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
  }

  #addAddressInactiveInput {
    margin-left: calc(10% - 15px);
    width: 80%;
  }

  #addAddressFormDiv .animatedInputInputs {
    width: 80%;
  }

  #addAddressFormDiv .animatedInputLabelsSmallerLighterGray {
    left: 10%;
  }

  #addAddressFormDiv .animatedInputLabelsLighterGray {
    left: 10%;
  }

  #addAddressFormDiv h1 {
    margin-left: calc(10% - 15px);
  }
}

#smallNavDiv {
  position: relative;
  top: 0;
  left: 0;
  background-color: #333333;
  z-index: 2;
  height: 60px;
  width: 100%;
}

#topNav {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
}

#smallLogoImageDiv {
  position: absolute;
  top: 0%;
  left: 40px;
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  background-color: #b90504;
}

#smallLogoImage {
  width: 45px;
  height: 45px;
  z-index: 1;
  cursor: pointer;
  margin-top: -5px;
}

@media (max-width: 1024px) {
  #smallLogoImageDiv {
    left: 0;
  }
}

.paymentContainer {
  position: relative;
  width: calc(100% - 10px);
  max-width: 1000px;
  grid-gap: 10px;
  gap: 10px;
  color: #333333;
}

.paymentLeftDiv {
  width: 67%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.paymentRightDiv {
  position: relative;
  padding: 10px;
  width: 33%;
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
  grid-gap: 10px;
  gap: 10px;
}

.paymentAddressDiv {
  background-color: #e8e8e8;
  font-family: Montserrat;
  padding: 10px 15px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  grid-gap: 5px;
  gap: 5px;
}

.paymentAddressDiv h1 {
  font-size: 20px;
  font-weight: 700;
}

.paymentAddressDiv p {
  font-size: 15px;
}

.paymentAddressDiv span {
  font-weight: 500;
}

.paymentAddressDiv button {
  margin-top: 10px;
}

.paymentProductsDiv {
  background-color: #e8e8e8;
  margin: 10px 0 0 0;
  border-radius: 10px;
  font-family: Montserrat;
  height: 280px;
  overflow-y: scroll;
}

.paymentProductsDiv::-webkit-scrollbar {
  width: 6px;
}

.paymentProductsDiv::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 3px;
}

.paymentTotalDiv {
  padding: 5px 0px;
  font-weight: 500;
  font-size: 20px;
  border-top: 4px solid white;
}

.paymentProductsCard {
  padding: 9px 10px;
  grid-gap: 20px;
  gap: 20px;
  border-bottom: 1px solid white;
}

.paymentProductTextDiv {
  width: 75%;
}

.paymentProductTextDiv h2 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 3px;
}

.paymentProductTextDiv p {
  font-weight: 500;
  font-size: 14px;
}

.paymentProductPriceDiv {
  text-align: right;
  width: 25%;
}

.paymentProductPriceDiv p {
  font-size: 15px;
  font-weight: 500;
}

.paymentOrderingForDiv {
  width: 100%;
  background-color: #e8e8e8;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Montserrat;
}

.paymentOrderingForDiv p {
  margin: 10px 0 2px 0px;
}

.paymentOrderingForDiv span {
  font-weight: 600;
}

.paymentRightDivHeaders {
  font-size: 19px;
  margin-bottom: 5px;
  font-weight: 600;
}

.paymentRightDivSummary {
  width: 100%;
  background-color: #e8e8e8;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Montserrat;
  grid-gap: 5px;
  gap: 5px;
}

.paymentRightDivOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 25px;
  font-weight: 700;
  font-family: Montserrat;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

@media (max-width: 780px) {
  .paymentContainer {
    flex-direction: column;
    position: relative;
    top: 60px;
  }

  .paymentLeftDiv {
    width: 100%;
  }

  .paymentRightDiv {
    width: 100%;
  }

  .paymentAddressDiv {
    padding: 5px;
  }
}

#footer {
  position: relative;
  width: 100%;
  background-color: #333333;
  margin-top: auto;
}

#footerBottom {
  width: 100%;
  height: 45px;
  background-color: #b90504;
}

#footerBottom h1 {
  color: white;
  font-family: Spartan;
  font-weight: bold;
  font-size: 35px;
  margin-top: 5px;
}

#footerLinksDiv {
  padding: 30px 0px;
  grid-gap: 6%;
  gap: 6%;
  width: 90%;
}

.footerColumns {
  max-width: 33%;
}

.footerColumns h2 {
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
}

.footerColumns a {
  color: white;
  text-decoration: none;
  font-family: Roboto;
  font-weight: 300;
  font-size: 17px;
}

@media (max-width: 780px) {
  .footerColumns h2 {
    font-size: 15px;
  }

  .footerColumns a {
    font-size: 12px;
  }
}

#loginBodyTop {
  width: 100%;
  height: 35vh;
  background-color: #b90504;
  display: flex;
  justify-content: center;
}

#loginBodyTop h1 {
  font-family: Spartan;
  color: white;
  font-weight: 900;
  font-size: 60px;
  margin: 35px 0px;
}

#loginBodyBottom {
  position: absolute;
  top: 35vh;
  width: 100%;
  height: 65vh;
  background-color: #333333;
}

#loginContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 380px;
  height: 280px;
  background-color: rgb(34, 34, 34);
  border-radius: 20px;
}

#loginForm {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 35px;
  gap: 35px;
}

#loginForm button {
  border: none;
  background-color: #b90504;
  color: white;
  height: 40px;
  padding: 0px 10px;
  border-radius: 20px;
  font-family: Montserrat;
  font-weight: bold;
  cursor: pointer;
}

#goToLoginAndRegister {
  color: white;
  text-decoration: none;
  font-family: Montserrat;
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

#registerBodyTop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 35vh;
  background-color: #b90504;
  display: flex;
  justify-content: center;
}

#registerBodyTop h1 {
  font-family: Spartan;
  color: white;
  font-weight: 900;
  font-size: 60px;
  margin: 10px 0px;
}

#registerBodyBottom {
  position: fixed;
  top: 35vh;
  width: 100%;
  height: 65vh;
  background-color: #333333;
}

#registerContainerParentDiv {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
}
#registerContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  background-color: rgb(34, 34, 34);
  width: 450px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
}

#registerContainer h1 {
  font-family: Spartan;
  color: white;
  font-weight: bold;
  font-size: 40px;
  margin: 20px 0px;
  margin-bottom: 40px;
}

#registerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 25px;
}

#registerOTPOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

#registerOTPContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -59%);
          transform: translate(-50%, -59%);
  width: 450px;
  height: 220px;
  background-color: rgb(34, 34, 34);
  border: 2px solid white;
  border-radius: 20px;
}

#registerOTPTop {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #b90504;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0 0;
}

#registerOTPForm {
  position: absolute;
  top: 80px;
  height: 140px;
  width: 100%;
}

#registerOTPButtonsDiv {
  position: absolute;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

#registerResendOTPH2 {
  position: absolute;
  bottom: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

#registerResendOTPH2:hover {
  font-weight: 500;
}

#searchPageFilterBox {
  position: relative;
  width: 20%;
  border-radius: 20px;
  box-sizing: border-box;
  grid-gap: 15px;
  gap: 15px;
}

.subFiltersDiv {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: Montserrat;
  box-sizing: border-box;
}

.subFiltersHeaderDiv {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 15px;
  border-bottom: 2px solid #e8e8e8;
}

.subFiltersHeader {
  font-size: 20px;
  color: dimgray;
  font-weight: 500;
}

.subFiltersContentDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  box-sizing: border-box;
}

.subFiltersCheckboxDiv {
  grid-gap: 5px;
  gap: 5px;
}

.subFiltersCheckboxDiv label {
  font-size: 15px;
  font-weight: 600;
}

.subFiltersDiv input[type="checkbox"] {
  margin: 5px 0px;
  min-width: 15px;
  min-height: 15px;
}

.subFiltersDiv input[type="checkbox"]::after {
  content: "\2713";
  width: 100%;
  height: 100%;
  background-color: #b90504;
  display: none;
  font-size: 10px;
  color: white;
  font-weight: 900;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 2px;
}

.subFiltersDiv input[type="checkbox"]:checked {
  background-color: #e8e8e8;
  border: none;
}

.subFiltersDiv input[type="checkbox"]:checked::after {
  display: flex;
}

/* mobile styles */

.mobileSearchFilterBoxButton {
  position: sticky;
  bottom: 0px;
  margin-top: auto;
  height: 40px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  z-index: 1;
  border-radius: 10px 10px 0 0;
}

.mobileFilterPopUpDiv {
  position: fixed;
  top: calc(100% - 400px);
  bottom: 0;
  left: 0;
  background-color: #333333;
  width: 100%;
  min-height: 400px;
  border-radius: 10px 10px 0 0;
  -webkit-animation-name: filtersPopUp;
          animation-name: filtersPopUp;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

@-webkit-keyframes filtersPopUp {
  from {
    top: calc(100% - 40px);
  }

  to {
    top: calc(100% - 400px);
  }
}

@keyframes filtersPopUp {
  from {
    top: calc(100% - 40px);
  }

  to {
    top: calc(100% - 400px);
  }
}

@-webkit-keyframes filtersPopDown {
  from {
    top: calc(100% - 400px);
  }
  to {
    top: calc(100% - 40px);
  }
}

@keyframes filtersPopDown {
  from {
    top: calc(100% - 400px);
  }
  to {
    top: calc(100% - 40px);
  }
}

@media (max-width: 780px) {
  .subFiltersDiv {
    margin: 10px 10px;
    width: calc(100% - 20px);
  }
}

#productSearchPageMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  max-width: 1400px;
  margin: 30px 0px;
  padding: 0px 20px;
  box-sizing: border-box;
  grid-gap: 15px;
  gap: 15px;
}

#rightContainer {
  width: 80%;
}

#productSearchPageFilterBar {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b90504;
  border-radius: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0px 20px;
  box-sizing: border-box;
}

#searchResultsContainer {
  -webkit-transform: translateY(1vw);
          transform: translateY(1vw);
  width: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
}

.searchResultCards {
  position: relative;
  height: 210px;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  grid-gap: 10px;
  gap: 10px;
  overflow: hidden;
  padding-right: 10px;
  box-sizing: border-box;
}

#searchResultCardImageDiv {
  min-width: 210px;
  max-width: 210px;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
}

#searchResultCardImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.searchResultCardTextDiv {
  font-family: Montserrat;
  padding: 10px 0px;
  width: calc(100% - 220px);
  height: 100%;
  box-sizing: border-box;
  grid-gap: 2px;
  gap: 2px;
}

.searchResultCardTextDiv h1 {
  color: #222222;
  font-size: 15px;
  font-weight: 600;
}

.searchResultCardTextDiv h2 {
  color: dimgray;
  font-size: 12px;
  font-weight: 500;
}

.searchResultCardTextDiv:hover h1 {
  color: #b90504;
}

.searchResultCardPriceDiv {
  position: relative;
  background-color: #e8e8e8;
  margin-top: auto;
  max-width: 400px;
  border-radius: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  color: dimgray;
}

.searchResultCardPriceDiv h3 {
  font-weight: 500;
  font-size: 15px;
}

.searchResultCardPriceDiv h2 {
  font-weight: 500;
  font-size: 18px;
}

.searchResultCardPriceDiv h4 {
  font-size: 13px;
  font-weight: 500;
}

.searchResultCardPriceDiv span {
  color: #b90504;
}

.searchResultCardRating {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 5px;
  margin: 5px 0px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 12px;
}

.searchResultCardRatingDiv {
  grid-gap: 6px;
  gap: 6px;
}

.searchResultCardRatingDiv h4 {
  color: dimgray;
  font-size: 15px;
}

/* failed div styling */
#productsNoResultsDiv {
  font-family: Montserrat;
  grid-gap: 10px;
  gap: 10px;
  width: 80%;
  text-align: center;
}

#productsNoResultsDiv a {
  margin: 10px 0 0 0;
}

@media (max-width: 1200px) {
  #searchResultsContainer {
    grid-template-columns: 1fr;
  }
}

/* mobile css */
@media (max-width: 780px) {
  #productSearchPageMainContainer {
    margin: 10px 0px 0 0;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    min-height: 72vh;
  }

  #searchResultsContainer {
    padding: 0px 5px;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr;
  }

  .searchResultCards {
    padding-right: 10px;
    border-radius: 10px;
    height: 180px;
  }

  #searchResultCardImageDiv {
    min-width: 120px;
    max-width: 120px;
  }

  .searchResultCardTextDiv {
    padding: 5px 0 10px 0px;
    width: calc(100% - 130px);
  }

  .searchResultCardTextDiv h1 {
    font-size: 15px;
  }

  .searchResultCardTextDiv h2 {
    font-size: 10px;
  }

  .searchResultCardRating {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 5px;
    margin: 5px 0px;
    background-color: #b90504;
    color: white;
    font-family: Montserrat;
    font-weight: bolder;
    border-radius: 5px;
    font-size: 10px;
  }

  .searchResultCardRatingDiv {
    grid-gap: 6px;
    gap: 6px;
  }

  .searchResultCardRatingDiv h4 {
    font-size: 13px;
  }

  .searchResultCardPriceDiv {
    border-radius: 10px;
  }

  .searchResultCardPriceDiv h3 {
    font-size: 13px;
  }

  .searchResultCardPriceDiv h2 {
    font-size: 14px;
  }

  .searchResultCardPriceDiv h4 {
    font-size: 12px;
  }

  #productSearchPageFilterBar {
    width: calc(100% - 10px);
    height: 50px;
    border-radius: 15px;
    margin: 0px 5px;
    padding: 0px 10px;
  }

  #productSearchPageFilterBar label {
    font-size: 13px;
  }

  #productSearchPageFilterBar select {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  #searchResultsContainer {
    grid-template-columns: 1fr;
  }
}

#productPageMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  box-sizing: border-box;
  margin: 20px 0px;
  display: flex;
  max-width: 1200px;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 10px;
}

.productPageReviewMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  max-width: 1200px;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

#productLeftDiv {
  width: 30%;
  position: sticky;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 10px;
  grid-gap: 10px;
  gap: 10px;
}

.productLeftDivImageDiv {
  position: relative;
  width: 100%;
  min-height: 360px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.productLeftDivImageDiv img {
  max-width: 100%;
  max-height: 480px;
}

.productLeftDivOtherImagesDiv {
  position: absolute;
  top: 5px;
  left: -60px;
  grid-gap: 5px;
  gap: 5px;
}

.productLeftDivOtherImagesDiv div {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  background-color: white;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  opacity: 0.8;
}

.productLeftDivOtherImagesDiv .activeImage {
  border: 2px solid #b90504;
  opacity: 1;
}

.productLeftDivOtherImagesDiv div img {
  max-height: 100%;
  max-width: 100%;
}

#productsMiddleDiv {
  width: 45%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#productsRightDiv {
  width: 25%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  position: sticky;
  top: 10px;
}

#selectedShopProductRightDiv {
  position: relative;
  background-color: white;
  width: 100%;
  padding: 1px 0px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
}

.productMiddleDivSections {
  position: relative;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.productMiddleDivSectionHeader {
  font-family: Montserrat;
  color: dimgray;
  font-size: 20px;
  margin: 5px 15px;
  margin-top: 14px;
}

.productMiddleDivProductName {
  margin: 15px 20px;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
}

.productMiddleDivSectionPriceDiv {
  background-color: #e8e8e8;
  margin: 15px 15px;
  margin-top: 30px;
  padding: 10px 10px;
  border-radius: 10px;
}

.productMiddleDivSectionPriceDiv h3 {
  font-family: Montserrat;
  color: dimgray;
  font-size: 16.5px;
  font-weight: 500;
}

.productMiddleDivSectionPriceDiv h3 span {
  color: #b90504;
  text-decoration: line-through;
}

.productMiddleDivSectionPriceDiv h4 {
  font-family: Montserrat;
  color: dimgray;
  font-size: 15px;
  font-weight: 500;
}

.productMiddleDivSectionPriceDiv h4 span {
  color: #b90504;
}

.productMiddleDivSectionPrice {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  color: dimgray;
  margin-bottom: 5px;
}

.productMiddleDivSectionPrice span {
  font-weight: 700;
  color: #b90504;
}

.productMiddleDivRatingDiv {
  margin: 0 0 0 20px;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
}

.productMiddleDivRatingDiv h1 {
  margin: 0px;
  font-size: 17px;
  color: #555555;
  font-family: Montserrat;
}

.productMiddleDivRating {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 5px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 13px;
}

.productMiddleDivDescription {
  color: #333333;
  margin: 10px 15px;
  margin-bottom: 15px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 15px;
}

.productMiddleDivSpecsCategoryDiv {
  background-color: #e8e8e8;
  margin: 10px 15px;
  padding: 1px 0px;
  border-radius: 10px;
}

.productMiddleDivSpecsHeader {
  color: black;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 9px 10px;
  margin-bottom: 10px;
}

.productMiddleDivSpecsFields {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  font-family: Montserrat;
  margin: 5px 0px;
  padding: 5px 10px;
  border-top: 2px solid white;
}

.productMiddleDivSpecsFields h1 {
  width: 30%;
  font-weight: 300;
  font-size: 14px;
}

.productMiddleDivSpecsFields h2 {
  width: 70%;
  font-weight: 500;
  font-size: 14px;
}

.selectedShopDetailDiv {
  position: relative;
  background-image: url(https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1476&q=80);
  background-size: 100%;
  border-radius: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  height: 130px;
  overflow: hidden;
  margin: 10px 15px;
}

.selectedShopDetailOverlayDiv {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  padding: 1px 0px;
}

.selectedShopDetailOverlayDiv:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.selectedShopDetailOverlayDiv h1 {
  color: white;
  font-family: Montserrat;
  font-size: 20px;
  margin: 0px 10px;
  margin-top: 4px;
}

.selectedShopDetailOverlayDiv h2 {
  color: white;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: 15px;
  margin-left: 10px;
}

.productRightDivRatingDiv {
  grid-gap: 10px;
  gap: 10px;
  margin: 5px 0px;
}

.productRightDivRating {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 5px;
  margin-left: 10px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 11px;
}

.productRightDivRatingDiv h1 {
  font-size: 15px;
  color: white;
  font-family: Montserrat;
  margin: 0px 0px;
}

.mapMarkerHoverDiv {
  position: relative;
  background-size: 100%;
  height: 100px;
  width: 200px;
  border-radius: 7px;
  overflow: hidden;
}

.mapPopUpOverlayDiv {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  padding: 1px 0px;
}

.mapPopUpOverlayDiv:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.mapPopUpOverlayDiv h1 {
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  margin: 0px 10px;
  margin-top: 4px;
}

.mapPopUpOverlayDiv h2 {
  color: white;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: 15px;
}

.mapPopUpRatingDiv {
  grid-gap: 10px;
  gap: 10px;
  margin: 5px 0px;
}

.mapPopUpRating {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 5px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 11px;
}

.mapboxgl-popup-content {
  padding: 5px !important;
  border-radius: 10px !important;
}

#productRightDivButtons {
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 10px;
  padding: 0px 15px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.productPageReviewContainer {
  width: 100%;
  box-sizing: border-box;
}

.productPageAddReviewContainer {
  width: 100%;
  height: 100px;
  padding: 0px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: sticky;
  top: 10px;
  overflow: hidden;
}

.productReviewCard {
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  font-family: Montserrat;
  grid-gap: 10px;
  gap: 10px;
  border: 2px solid #e8e8e8;
}

.productReviewCard h1 {
  font-size: 15px;
  margin-left: auto;
}

.productReviewCard p {
  font-size: 16px;
  display: flex;
  padding: 10px 15px;
  background-color: #e8e8e8;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.productReviewCard span {
  color: dimgray;
  font-weight: 500;
}

.productReviewCard div:nth-child(1) {
  grid-gap: 10px;
  gap: 10px;
}

.productReviewCard .productMiddleDivRating {
  font-size: 17px;
  flex-shrink: 0;
}

@media (max-width: 1200px) and (min-width: 481px) {
  #productPageMainContainer {
    margin: 10px 0px;
    padding: 0px 10px;
    box-sizing: border-box;
    max-width: 780px;
  }

  .productPageReviewMainContainer {
    padding: 0px 10px;
    box-sizing: border-box;
  }

  #productsRightDiv {
    width: 40%;
  }

  #productsMiddleDiv {
    width: 60%;
  }

  #productLeftDiv {
    position: relative;
    top: 0;
    width: 100%;
    border-radius: 10px;
  }

  #selectedShopProductRightDiv {
    border-radius: 10px;
  }

  .productMiddleDivSections {
    border-radius: 10px;
  }

  .productMiddleDivRatingDiv {
    margin: 0 0 10px 20px;
  }

  .productMiddleDivProductName {
    font-size: 19px;
  }

  .productReviewCard p {
    font-size: 16px;
  }

  .productReviewCard div:nth-child(1) {
    flex-direction: column;
  }

  .productReviewCard {
    border-radius: 9px;
  }
}

@media (max-width: 780px) {
  .productLeftDivOtherImagesDiv {
    top: auto;
    left: 0px;
    bottom: 10px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  #productPageMainContainer {
    margin: 10px 0px;
  }

  .productPageReviewMainContainer {
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .productReviewCard p {
    font-size: 16px;
  }

  .productReviewCard div:nth-child(1) {
    flex-direction: column;
  }

  #productsMiddleDiv {
    margin: 0px 5px;
    width: calc(100% - 10px);
  }

  .productMiddleDivSections {
    border-radius: 10px;
  }

  .productMiddleDivProductName {
    margin: 10px 15px;
    font-size: 18px;
  }

  .productMiddleDivRatingDiv {
    margin: 0 0 10px 15px;
  }

  #productLeftDiv {
    position: relative;
    top: 0;
    width: 100%;
    border-radius: 10px;
  }

  #productsRightDiv {
    width: 100%;
    position: relative;
    top: 0px;
  }

  #selectedShopProductRightDiv {
    border-radius: 10px;
  }

  .selectedShopDetailDiv {
    height: auto;
    aspect-ratio: 2/1;
  }

  .selectedShopDetailOverlayDiv h1 {
    font-size: 24px;
  }

  .selectedShopDetailOverlayDiv h2 {
    font-size: 18px;
  }

  .productRightDivRatingDiv h1 {
    font-size: 15px;
  }

  .productMiddleDivSpecsCategoryDiv {
    margin: 10px;
  }

  .productReviewCard {
    border-radius: 9px;
  }
}

.addReviewsContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: calc(100% - 10px);
  max-width: 600px;
  background-color: white;
  border-radius: 20px;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
}

.addReviewsProductInfo {
  margin: 50px 0 10px 0;
  width: 100%;
  height: 80px;
  background-color: #e8e8e8;
  border-radius: 10px;
}

.addReviewsProductInfo h1 {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
}

.addReviewsProductImageDiv {
  height: 80px;
  min-width: 80px;
  max-width: 80px;
  padding: 5px 0px;
  box-sizing: border-box;
}

.addReviewsProductImageDiv img {
  max-height: 100%;
  min-height: 100%;
}

.addReviewsForm {
  width: 100%;
  margin: 10px 0px;
  grid-gap: 10px;
  gap: 10px;
}

.addReviewsForm input[type="range"] {
  -webkit-appearance: none;
  width: 80%;
  height: 10px;
  background-color: #e8e8e8;
  border-radius: 10px;
}

.addReviewsForm input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #990100;
}

.addReviewsSetReviewDiv {
  padding: 3px 7px;
  background: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 5px;
}

.addReviewsForm textarea {
  width: 100%;
  height: 150px;
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: #e8e8e8;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 500;
}

#shopDetailsMainContainer {
  margin: 20px 0;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 1100px;
  grid-gap: 12px;
  gap: 12px;
}

.infoCardParentDiv {
  width: 28%;
  grid-gap: 10px;
  gap: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: 10px;
}

.infoCard {
  position: relative;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.shopInfoImageDiv {
  background-image: url(https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1476&q=80);
  background-size: 100%;
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
}

.shopImageOverlayDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-end;
  padding-bottom: 10px;
  box-sizing: border-box;
  transition: background-color 200ms ease-in;
  font-size: 10px;
  color: white;
  font-family: Montserrat;
}

.infoCard:hover .shopImageOverlayDiv {
  background-color: rgba(0, 0, 0, 0);
}

.shopRatingDiv {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 5px;
  margin-left: 10px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 18px;
}

.shopInfoTextDiv {
  font-family: Montserrat;
  padding: 20px 15px;
  width: 100%;
  color: #222222;
  box-sizing: border-box;
}

.shopInfoTextDiv h1 {
  font-size: 23px;
}

.shopInfoTextDiv h2 {
  color: #333333;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

.shopInfoTextDiv h3 {
  font-weight: 700;
  margin-top: 60px;
  font-size: 20px;
}

#rightDivContainer {
  width: 72%;
  border-radius: 20px;
  position: relative;
}

#shopInventoryTools {
  background-color: #b90504;
  height: 55px;
  width: 100%;
  border-radius: 27.5px;
}

.shopDetailsInventorySearch {
  background-color: #e8e8e8;
  border: none;
  height: 40px;
  border-radius: 20px;
  margin-right: 7px;
  padding: 0px 17px;
  width: 250px;
  font-size: 18px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-weight: 600;
}

.shopDetailsInventorySearch:focus {
  outline: none;
}

#shopInventoryToolsForm {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

#shopDetailsCategorySelect {
  margin-right: 5px;
}

.shopDetailsInventoryCards {
  background-color: white;
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  grid-gap: 15px;
  gap: 15px;
  position: relative;
}

#shopDetailsInventoryImage {
  min-width: 200px;
  max-width: 200px;
  height: 100%;
}

#shopDetailsInventoryImage img {
  max-width: 100%;
  max-height: 100%;
}

#shopDetailsInventoryTextDiv {
  font-family: Montserrat;
  padding: 15px 5px 10px 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

#shopDetailsInventoryTextDiv h1 {
  color: #222222;
  font-size: 16px;
}

#shopDetailsInventoryTextDiv:hover#shopDetailsInventoryTextDiv h1 {
  color: #b90504;
}

.shopDetailsInventoryCardParentDiv {
  border-radius: 20px;
  margin: 12px 0px;
}

#shopDetailsProductTypeDiv {
  margin-left: 7px;
  height: 40px;
  grid-gap: 5px;
  gap: 5px;
  z-index: 2;
}

#shopDetailsProductTypeDiv div {
  border-radius: 20px;
  font-family: Montserrat;
  font-size: 12px;
  color: white;
  padding: 0px 20px;
  height: 100%;
  cursor: pointer;
}

#shopDetailsProductTypeDiv div h1 {
  font-weight: 700;
}

#shopDetailsProductTypeDiv div:hover {
  background-color: #990100;
}

.shopDetailsTabActive {
  background-color: white !important;
  color: #b90504 !important;
}

.shopDetailsInventoryPriceDiv {
  background-color: #e8e8e8;
  height: 90px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  padding-left: 15px;
  color: dimgray;
  margin-right: 10px;
}

.shopDetailsInventoryPriceDiv h3 {
  font-weight: 500;
  font-size: 16.5px;
}

.shopDetailsInventoryPriceDiv h2 {
  font-weight: 500;
  font-size: 21px;
}

.shopDetailsInventoryPriceDiv h4 {
  font-weight: 400;
  font-size: 15px;
  margin-top: 5px;
}

.shopDetailsInventoryPriceDiv span {
  color: #b90504;
}

.shopDetailsInventoryButtonsDiv {
  height: 90px;
  min-width: 220px;
  margin: 0px 20px 0 auto;
  grid-gap: 5px;
  gap: 5px;
  z-index: 2;
}

.shopDetailsInventoryNoProductsDiv {
  position: relative;
  background: white;
  width: 100%;
  height: 200px;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  color: dimgray;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#shopDetailsReviewContainer {
  position: relative;
  background: white;
  width: 100%;
  margin: 10px 0 0 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: Montserrat;
  overflow: hidden;
}

#shopDetailsReviewContainer h1 {
  font-family: Montserrat;
  color: dimgray;
  font-size: 20px;
  margin: 5px 15px;
  margin-top: 14px;
}

.shopReviewCard {
  background-color: white;
  border-radius: 9px;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  font-family: Montserrat;
  grid-gap: 10px;
  gap: 10px;
  border: 2px solid #e8e8e8;
}

.shopReviewCard h2 {
  font-size: 15px;
  margin-left: auto;
}

.shopReviewCard p {
  font-size: 16px;
  display: flex;
  padding: 10px 15px;
  background-color: #e8e8e8;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.shopReviewCard span {
  color: dimgray;
  font-weight: 500;
}

.shopReviewCard div:nth-child(1) {
  grid-gap: 10px;
  gap: 10px;
}

.shopReviewCard .productMiddleDivRating {
  font-size: 17px;
  flex-shrink: 0;
}

@media (max-width: 780px) {
  #shopDetailsMainContainer {
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .infoCardParentDiv {
    width: 100%;
    position: relative;
    top: 0;
  }

  #rightDivContainer {
    width: 100%;
  }

  #shopInventoryTools {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 7px 7px;
    box-sizing: border-box;
    grid-gap: 10px;
    gap: 10px;
    overflow: hidden;
    border-radius: 15px;
  }

  #shopInventoryToolsForm {
    width: 100%;
  }

  .shopDetailsInventorySearch {
    width: 100%;
    margin: 0;
    font-size: 20px;
    border-radius: 10px;
  }

  #shopDetailsProductTypeDiv {
    width: 100%;
    margin: 0;
  }

  #shopDetailsProductTypeDiv div {
    padding: 0px;
    width: 50%;
    border-radius: 10px;
  }

  #shopDetailsProductTypeDiv div:nth-last-of-type(1) {
    margin-left: auto;
  }

  .shopInfoTextDiv {
    padding: 10px 15px 20px 15px;
  }

  .shopInfoTextDiv h1 {
    font-size: 26px;
  }

  .shopInfoTextDiv h2 {
    margin-top: 10px;
  }

  .shopInfoTextDiv h3 {
    font-size: 21px;
  }

  .shopDetailsInventoryCards {
    height: 170px;
    border-radius: 10px;
  }

  #shopDetailsInventoryImage {
    min-width: 110px;
    max-width: 110px;
    height: 100%;
  }

  #shopDetailsInventoryTextDiv h1 {
    font-size: 14px;
  }

  .shopDetailsInventoryPriceDiv {
    min-height: 60px;
    height: auto;
  }

  .shopDetailsInventoryPriceDiv h2 {
    font-size: 16px;
  }

  .shopDetailsInventoryPriceDiv h3 {
    font-size: 13px;
  }

  .shopDetailsInventoryPriceDiv h4 {
    font-size: 14px;
  }

  #shopDetailsInventoryTextDiv .groceryTag {
    font-size: 12px;
    padding: 3px 6px;
  }
}

@media (max-width: 480px) {
  .shopReviewCard div:nth-child(1) {
    flex-direction: column;
  }
}

.addReviewsShopInfo {
  margin: 50px 0 10px 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #e8e8e8;
  border-radius: 10px;
}

.addReviewsShopInfo h1 {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
}

#errorPageBody {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#errorPageContainer {
  position: relative;
  height: 70vh;
  width: 70vw;
  background-color: #333333;
  border-radius: 20px;
}

#errorPageContainer h1 {
  position: relative;
  top: -10vh;
  color: white;
  font-family: Roboto;
  font-size: 27vh;
}

#errorPageContainer h2 {
  position: relative;
  top: -10vh;
  color: white;
  font-size: 5.4vh;
  font-family: Montserrat;
  margin-top: -3vh;
  margin-bottom: 3vh;
}

#errorPageContainer h3 {
  position: relative;
  top: -8vh;
  color: gray;
  font-family: Montserrat;
  font-size: 3vh;
  text-align: center;
}

#errorPageHomeButton {
  position: absolute;
  bottom: 5vh;
}

@media (max-width: 1280px) {
  #errorPageContainer {
    width: 80vw;
  }
}

@media (max-width: 1000px) {
  #errorPageContainer h1 {
    position: relative;
    top: -10vh;
    color: white;
    font-family: Roboto;
    font-size: 22vh;
  }

  #errorPageContainer h2 {
    position: relative;
    top: -10vh;
    color: white;
    font-size: 4vh;
    font-family: Montserrat;
    margin-top: -3vh;
    margin-bottom: 3vh;
  }

  #errorPageContainer h3 {
    position: relative;
    top: -8vh;
    color: gray;
    font-family: Montserrat;
    font-size: 1.5vh;
  }
}

@media (max-width: 480px) {
  #errorPageContainer {
    width: 90vw;
    top: 25px;
  }
}

#grocerySearchPageMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  max-width: 1400px;
  margin: 30px 0px;
  padding: 0px 20px;
  box-sizing: border-box;
  grid-gap: 15px;
  gap: 15px;
}

#grocerySearchPageAutoFilterBar {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b90504;
  border-radius: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0px 20px;
  box-sizing: border-box;
}

#grocerySearchPageAutoFilterBar h1 {
  color: white;
  margin: 0px 30px;
  font-size: 25px;
  font-family: Montserrat;
  font-weight: 500;
  cursor: pointer;
}

#grocerySearchPageRightContainer {
  position: relative;
  width: 80%;
}

.grocerySearchResultsContainer {
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
  flex-wrap: wrap;
  margin: 15px 0px;
}

/* cards for auto */
.grocerySearchResultCards {
  position: relative;
  background-color: white;
  width: calc(50% - 7.5px);
  max-width: 100%;
  height: 160px;
  border-radius: 20px;
  grid-gap: 10px;
  gap: 10px;
  overflow: hidden;
}

.grocerySearchResultImageDiv {
  height: 100%;
  min-width: 160px;
  max-width: 160px;
  border-right: 2px solid #e8e8e8;
}

.grocerySearchResultImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.grocerySearchResultTextDiv {
  font-family: Montserrat;
  box-sizing: border-box;
  padding: 8px 10px 8px 0;
}

.grocerySearchResultTextDiv:hover.grocerySearchResultTextDiv h1 {
  color: #b90504;
}

.grocerySearchResultTextDiv h1 {
  font-size: 17px;
  color: black;
  margin-bottom: 5px;
}

.grocerySearchResultTextDiv h2 {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.grocerySearchResultTextDiv h3 {
  font-size: 12px;
  font-weight: 500;
  color: dimgray;
  margin-bottom: 2px;
}

.grocerySearchResultTextDiv p {
  color: black;
  font-size: 15px;
  margin-top: auto;
}

.grocerySearchResultTextDiv span {
  font-weight: 600;
  color: #b90504;
}

.grocerySearchResultAddDiv {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background-color: #b90504;
  color: white;
  font-weight: 900;
  font-size: 25px;
  cursor: pointer;
  overflow: hidden;
}

.grocerySearchResultAddDiv:hover {
  background-color: #990100;
}

.grocerySearchPageQuantityDiv {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #b90504;
  width: 80px;
  height: 30px;
  border-radius: 20px;
  overflow: hidden;
  font-family: Montserrat;
}

.grocerySearchPageQuantityButton {
  width: 33%;
  color: white;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.grocerySearchPageQuantityButton:hover {
  background-color: #990100;
}

.grocerySearchPageQuantity {
  width: 34%;
  color: white;
}

/* cards for manual */
.groceryManualSearchResultCardParentDiv {
  width: calc(50% - 7.5px);
  background-color: #333333;
  border-radius: 15px;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #333333;
}

.groceryManualSearchResultShopHeader {
  color: white;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 24px;
  margin-left: 5px;
}

@media (max-width: 780px) {
  #grocerySearchPageMainContainer {
    min-height: 72vh;
    margin: 10px 0px 0 0;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
  }

  #grocerySearchPageAutoFilterBar {
    width: calc(100% - 10px);
    height: 50px;
    border-radius: 15px;
    margin: 0px 5px;
    padding: 0px 10px;
  }

  #grocerySearchPageAutoFilterBar label {
    font-size: 13px;
  }

  #grocerySearchPageAutoFilterBar select {
    font-size: 13px;
  }

  .grocerySearchResultsContainer {
    margin: 0;
    padding: 0px 5px;
    box-sizing: border-box;
    grid-gap: 10px;
    gap: 10px;
  }

  .grocerySearchResultImageDiv {
    min-width: 106px;
    max-width: 106px;
  }

  .grocerySearchResultTextDiv {
    padding: 5px 0 10px 0px;
  }

  .grocerySearchResultTextDiv h1 {
    font-size: 15px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    font-weight: 600;
  }

  .grocerySearchResultTextDiv h2 {
    margin-top: 5px;
    font-size: 16px;
  }

  .grocerySearchResultTextDiv h3 {
    font-size: 10px;
  }

  .groceryManualSearchResultShopHeader {
    font-size: 18px;
  }
}

@media (max-width: 680px) {
  .groceryManualSearchResultCardParentDiv {
    width: 100%;
  }

  .grocerySearchResultCards {
    width: 100%;
  }
}

#groceryPageMainContainer {
  position: relative;
  left: 50%;
  margin: 20px 0px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 1200px;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 40px;
}

/* css for this page comes from the products page, sorry :) */

@media (max-width: 780px) and (min-width: 481px) {
  #groceryPageMainContainer {
    margin: 10px 0px;
    padding: 0px 10px;
    box-sizing: border-box;
  }
}

@media (max-width: 480px) {
  #groceryPageMainContainer {
    margin: 10px 0px;
  }
}

.userOrderPageMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 20px 0px;
  max-width: 940px;
  padding: 0px 5px;
  box-sizing: border-box;
}

.userOrdersPageTopBar h1 {
  color: dimgray;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 28px;
  margin: 0 0 18px 10px;
}

.userOrdersCard {
  background-color: #333333;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.userOrdersInfoTopBar {
  background-color: #333333;
  height: 70px;
  border-radius: 20px 20px 0 0;
  padding: 0px 10px;
  box-sizing: border-box;
  font-family: Montserrat;
  color: white;
  margin-bottom: 10px;
  border-bottom: 2px solid #e8e8e8;
}

.userOrdersInfoDiv {
  height: 100%;
  grid-gap: 10px;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.userOrdersDeliveryStatusTag {
  height: 34px;
  border-radius: 17px;
  padding: 0px 20px;
  margin-left: auto;
  font-weight: 600;
}

.userOrdersDeliveryStatusTag.Pending {
  background-color: rgb(255, 191, 0);
}

.userOrdersDeliveryStatusTag.On-Route {
  background-color: rgb(80, 168, 255);
}

.userOrdersDeliveryStatusTag.Completed {
  background-color: green;
}

.userOrdersDeliveryStatusTag.Cancelled {
  background-color: #b90504;
}

.userOrderProductCard {
  background-color: white;
  height: 130px;
  border-radius: 10px;
  margin: 0px 10px 10px 10px;
  overflow: hidden;
  grid-gap: 10px;
  gap: 10px;
}

.userOrderProductImageDiv {
  height: 100%;
  min-width: 130px;
  max-width: 130px;
}

.userOrderProductImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.userOrderProductTextDiv {
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #333333;
  width: 100%;
  grid-gap: 5px;
  gap: 5px;
}

.userOrderProductTextDiv h1 {
  font-size: 16px;
  font-weight: 600;
}

.userOrderProductTextDiv:hover h1 {
  color: #b90504;
}

.userOrderProductTextDiv h3 {
  color: dimgray;
  font-weight: 400;
  font-size: 15px;
}

.userOrderProductTextDiv h2 {
  font-size: 18px;
  font-weight: 600;
  margin-top: auto;
}

.userOrderProductTextDiv h2 span {
  color: #b90504;
}

.userOrderDeclineTag {
  font-family: Montserrat;
  background-color: #b90504;
  color: white;
  font-size: 15px;
  font-weight: 600;
  height: 30px;
  padding: 0px 10px;
  border-radius: 15px;
  margin: 5px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.userOrdersBottomBar {
  height: 60px;
  border-top: 2px solid #e8e8e8;
  padding: 0px 10px;
}

.userOrdersBottomBar .redButton {
  font-weight: 400;
  font-size: 20px;
}

.ordersPageNoOrdersDiv {
  height: 140px;
  background-color: white;
  color: dimgray;
  border-radius: 20px;
  text-align: center;
  font-family: Montserrat;
  grid-gap: 5px;
  gap: 5px;
}

.ordersPageNoOrdersDiv h1 {
  font-weight: 700;
  font-size: 30px;
}

@media (max-width: 500px) {
  .userOrdersCard {
    border-radius: 10px;
  }

  .userOrdersInfoDiv {
    margin-left: 5px;
    font-size: 18px;
  }

  .userOrderProductCard {
    margin: 0 5px 10px 5px;
    width: calc(100% - 10px);
  }

  .userOrderProductImageDiv {
    min-width: 90px;
    max-width: 90px;
  }

  .userOrderProductTextDiv h1 {
    font-size: 14px;
  }

  .userOrderProductTextDiv h2 {
    font-size: 15px;
  }

  .userOrderProductTextDiv h3 {
    font-size: 13px;
  }

  .userOrdersInfoTopBar {
    border-radius: 10px 10px 0 0;
    height: 60px;
  }

  .ordersPageNoOrdersDiv {
    height: 100px;
    border-radius: 10px;
  }

  .ordersPageNoOrdersDiv h1 {
    font-size: 25px;
  }
}

.warningTopDiv {
  background-color: rgb(247, 206, 131);
  font-family: Montserrat;
  font-size: 15px;
  transition: height 200ms ease-in;
  font-weight: 500;
}

.warningTopDiv p {
  position: absolute;
  right: 10px;
  font-size: 35px;
  color: dimgray;
  cursor: pointer;
}

.promotionPageMainContainer {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 20px 0px;
  max-width: 1200px;
  width: calc(100% - 20px);
  padding: 15px;
  box-sizing: border-box;
  background-color: #333333;
  border-radius: 20px;
}

.promotionPagePromotionBanner {
  width: 100%;
  aspect-ratio: 21/8;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 15px;
}

.promotionPagePromotionBanner img {
  width: 100%;
  max-height: 100%;
}

.promotionPageProductsContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  gap: 15px;
}

.promotionPageProductCard {
  background-color: white;
  width: 100%;
  height: 200px;
  border-radius: 15px;
  overflow: hidden;
  grid-gap: 10px;
  gap: 10px;
}

.promotionPageProductCardImageDiv {
  width: 200px;
  height: 200px;
  min-width: 200px;
}

.promotionPageProductCardImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.promotionPageProductCardTextDiv {
  font-family: Montserrat;
  padding: 10px 10px 10px 0px;
  color: black;
  grid-gap: 2px;
  gap: 2px;
}

.promotionPageProductCardTextDiv h1 {
  color: #222222;
  font-size: 15px;
  font-weight: 600;
}

.promotionPageProductCardTextDiv h2 {
  color: dimgray;
  font-size: 12px;
  font-weight: 500;
}

.promotionPageProductCardRating {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 5px;
  margin: 5px 0px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 12px;
}

.promotionPageProductCardRatingDiv {
  grid-gap: 6px;
  gap: 6px;
}

.promotionPageProductCardRatingDiv h4 {
  color: dimgray;
  font-size: 15px;
}

.promotionPageProductCardPriceDiv {
  position: relative;
  background-color: #e8e8e8;
  margin-top: auto;
  max-width: 400px;
  border-radius: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  color: dimgray;
}

.promotionPageProductCardPriceDiv h3 {
  font-weight: 500;
  font-size: 15px;
}

.promotionPageProductCardPriceDiv h2 {
  font-weight: 500;
  font-size: 18px;
}

.promotionPageProductCardPriceDiv h4 {
  font-size: 13px;
  font-weight: 500;
}

.promotionPageProductCardPriceDiv span {
  color: #b90504;
}

@media (max-width: 1024px) {
  .promotionPageProductsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .promotionPageMainContainer {
    width: 100%;
    margin: 0px;
    border-radius: 0;
    padding: 0px 0px 10px 0;
  }

  .promotionPagePromotionBanner {
    border-radius: 0px;
    margin-bottom: 10px;
  }

  .promotionPageProductsContainer {
    grid-gap: 10px;
    gap: 10px;
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .promotionPageProductCard {
    border-radius: 10px;
    height: 180px;
  }

  .promotionPageProductCardTextDiv h1 {
    font-size: 15px;
  }

  .promotionPageProductCardTextDiv h2 {
    font-size: 10px;
  }

  .promotionPageProductCardRating {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 5px;
    margin: 5px 0px;
    background-color: #b90504;
    color: white;
    font-family: Montserrat;
    font-weight: bolder;
    border-radius: 5px;
    font-size: 10px;
  }

  .promotionPageProductCardRatingDiv {
    grid-gap: 6px;
    gap: 6px;
  }

  .promotionPageProductCardRatingDiv h4 {
    font-size: 13px;
  }

  .promotionPageProductCardPriceDiv {
    border-radius: 10px;
  }

  .promotionPageProductCardPriceDiv h3 {
    font-size: 13px;
  }

  .promotionPageProductCardPriceDiv h2 {
    font-size: 14px;
  }

  .promotionPageProductCardPriceDiv h4 {
    font-size: 12px;
  }

  .promotionPageProductCardImageDiv {
    width: 120px;
    min-width: 130px;
    height: 180px;
  }
}

