#navDiv {
  position: relative;
  height: 90px;
  width: 100%;
  background-color: #333333;
  box-shadow: 0px 0px 6px black;
  z-index: 2;
}

#logoImage {
  position: absolute;
  top: 0%;
  left: 56px;
  width: 90px;
  height: 90px;
  z-index: 1;
  cursor: pointer;
}

#topNav {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #333333;
}

#bottomNav {
  position: absolute;
  top: 60px;
  left: 0%;
  width: 100%;
  height: 30px;
  background-color: #403d3d;
}

.bottomNavRightDiv {
  margin: 0px 5px 0px auto;
  height: 100%;
  gap: 30px;
}

.bottomNavRightDiv a {
  text-decoration: none;
}

.bottomNavRightDiv h1 {
  color: white;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
}

.bottomNavRightDiv h1:hover {
  font-weight: 600;
  font-size: 14.5px;
}
.bottomNavLeftDiv {
  height: 100%;
  gap: 30px;
  padding-right: 30px;
  border-right: 2px solid dimgray;
}

.navBarSearchBarDiv {
  position: absolute;
  left: 50%;
  top: 9px;
  transform: translateX(-50%);
  width: 40%;
  z-index: 2;
  border-radius: 21px;
  overflow: hidden;
  min-height: 42px;
}

.navBarSearchBarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.navBarSearchDropDown {
  width: 100%;
  background-color: white;
}

.navBarSearchTabDiv {
  font-family: Montserrat;
  gap: 10px;
  padding: 31px 10px 10px 10px;
  box-sizing: border-box;
}

.navBarSearchTabs {
  color: dimgray;
  font-weight: bold;
  font-size: 22px;
  height: 34px;
  padding: 0px 10px;
  border-radius: 17px;
  cursor: pointer;
}

.navBarSearchTabs.navBarSearchTabActive {
  background-color: #b90504;
  color: white;
}

.navBarSearchSuggestionsCard {
  background-color: white;
  border-top: 1px solid #e8e8e8;
  font-family: Montserrat;
  padding: 17px 15px;
  box-sizing: border-box;
  cursor: pointer;
  color: black;
}

.navBarSearchSuggestionsCard p {
  font-weight: 500;
}

.navBarSearchSuggestionsCard:hover {
  background-color: #e8e8e8;
}

.navBarSearchNoResultsDiv {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
  color: dimgray;
  padding: 5px;
}

#searchBar {
  position: absolute;
  top: 0px;
  background-color: #222222;
  color: white;
  width: 100%;
  height: 42px;
  border-radius: 21px;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 600;
  padding-left: 15px;
  border: none;
  transition: background-color 200ms ease-in;
  box-sizing: border-box;
  border: 3px solid #222222;
}

#searchBar:focus {
  outline: none;
  border: 3px solid #b90504;
  background-color: white;
  color: black;
}

#searchBar:focus + #searchButton {
  background-color: #b90504;
  transform: translateX(-6px);
}

#searchButton {
  top: 4.5px;
  position: absolute;
  right: 0px;
  transform: translateX(-12px);
  background-color: #222222;
  border-radius: 50%;
  border: none;
  height: 33px;
  width: 33px;
  cursor: pointer;
  border: none;
  transition: all 200ms ease-in;
}

#cartImageDiv {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0px;
  right: 10px;
}

#cartImageDiv:hover {
  background-color: #222222;
}

#infoH3 {
  position: absolute;
  color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: Montserrat;
  font-weight: 500;
}

#notSignedInDiv {
  position: absolute;
  top: 0px;
  right: 110px;
  height: 100%;
  cursor: pointer;
  padding: 0px 10px;
}

#notSignedInDiv:hover {
  background-color: #222222;
}

#notSignedInDiv h1 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

#notSignedInDiv h3 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: white;
}

#signedInDiv {
  height: 100%;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
}

#signedInDiv:hover {
  background-color: #222222;
}

#signedInDiv h1 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: bold;
  color: white;
  margin: -3px 0px;
}

#signedInDiv h3 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: white;
}

#navOrdersDiv {
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
}

#navOrdersDiv h1 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

#navOrdersDiv:hover {
  background-color: #222222;
}

#regionDiv {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 42px;
  background-color: #b90504;
  border-radius: 21px;
  gap: 5px;
  user-select: none;
  cursor: pointer;
  padding-right: 5px;
}

#regionDiv h1 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  color: white;
  margin-bottom: 3px;
  margin-left: 10px;
}

#dropDownDiv::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  right: 34px;
  background-color: white;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

#dropDownDiv {
  position: absolute;
  top: 58px;
  right: 190px;
  background-color: white;
  border-radius: 15px;
  z-index: 4;
  gap: 10px;
  height: 230px;
}

#accountColumnDropDownDiv {
  margin: 10px 10px;
  margin-left: 30px;
  font-family: Montserrat;
}

#accountColumnDropDownDiv a {
  text-decoration: none;
  color: #333333;
}

#accountColumnDropDownDiv h1 {
  font-size: 20px;
  margin-bottom: 5px;
}

#accountColumnDropDownDiv h3 {
  font-size: 17px;
  font-weight: 500;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
}

#accountColumnDropDownDiv h3:hover {
  background-color: #b90504;
  color: white;
}

#accountColumnDropDownDiv h2 {
  position: absolute;
  bottom: 15px;
  font-weight: 520;
  font-size: 22px;
  cursor: pointer;
  padding: 3px 5px;
  border-radius: 5px;
}

#accountColumnDropDownDiv h2:hover {
  background-color: #b90504;
  color: white;
}

#navDropDownOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}

#regionDropDownDiv {
  position: absolute;
  left: 18%;
  top: 60px;
  background-color: white;
  border-radius: 10px;
  width: 64%;
  z-index: 6;
}

#regionDropDownDiv::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50px;
  background-color: white;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

#regionDropDownDiv h1 {
  font-family: Montserrat;
  font-size: 24px;
  color: #333333;
  font-weight: 500;
  margin-top: 25px;
}

#regionDropDownDiv h2 {
  font-family: Montserrat;
  font-size: 12px;
  color: #333333;
  font-weight: 500;
  margin: 15px;
}

#notSignedInRegionDropDownDiv2 {
  width: 85%;
  background-color: #e8e8e8;
  border-radius: 10px;
  gap: 5px;
  margin-top: 10px;
}

#notSignedInRegionDropDownDiv2 h1 {
  font-family: Montserrat;
  font-size: 15px;
  color: #333333;
  font-weight: 500;
  margin-top: 10px;
}

#notSignedInRegionDropDownDiv2 button {
  margin-bottom: 15px;
}

#regionSearchBoxParentDiv {
  width: 85%;
  background-color: #e8e8e8;
  border-radius: 20px;
  margin-bottom: 10px;
  padding-right: 6px;
}

#regionSearchBoxDiv {
  position: relative;
  border: 3px solid #b90504;
  border-radius: 20px;
  width: 100%;
  height: 40px;
  background-color: white;
}

#regionSearchBoxDiv input {
  position: relative;
  left: 15px;
  height: 40px;
  width: 75%;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 500;
  border: none;
  background-color: transparent;
}

#regionSearchBoxDiv input:focus {
  outline: none;
}

#regionSearchBoxDiv button {
  position: absolute;
  top: 2.5px;
  right: 0%;
  transform: translateX(-3px);
  height: 35px;
  border-radius: 17.5px;
  gap: 5px;
  padding-left: 10px;
  padding-right: 5px;
}

.regionSearchSuggestionDiv {
  width: 100%;
  border-bottom: 1px solid white;
  padding-right: 6px;
  cursor: pointer;
}

.regionSearchSuggestionDiv h3 {
  margin: 10px 20px;
  font-family: Montserrat;
  font-size: 24px;
  color: #333333;
  font-weight: 500;
}

.regionSearchSuggestionDiv:hover {
  background-color: #d1d1d1;
}

#regionDropDownErrorDiv {
  background-color: #b90504;
  width: 90%;
  margin-bottom: 10px;
}

#regionDropDownErrorDiv h4 {
  color: white;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin: 15px 20px;
}

#signedInWithoutAddressRegionDropDownDiv2 {
  width: 85%;
  background-color: #e8e8e8;
  border-radius: 10px;
  gap: 5px;
  margin-top: 10px;
}

#signedInWithoutAddressRegionDropDownDiv2 h1 {
  font-family: Montserrat;
  font-size: 15px;
  color: #333333;
  font-weight: 500;
  margin-top: 10px;
}

#signedInWithAddressRegionDropDownDiv2 {
  position: relative;
  width: 85%;
  background-color: #e8e8e8;
  border-radius: 10px;
  margin-top: 10px;
  gap: 3px;
}

#signedInWithAddressRegionDropDownDiv2 h1 {
  margin: 0px 20px;
  margin-top: 8px;
  font-size: 17px;
  font-weight: 600;
}

#signedInWithAddressRegionDropDownDiv2 h2 {
  margin: 0px 20px;
  font-size: 17px;
  font-weight: 400;
}

/* mobile nav bar start*/

#mobileNavBar {
  position: relative;
  width: 100%;
  background-color: #333333;
  box-shadow: 0px 0px 6px black;
  gap: 5px;
  z-index: 2;
}

#mobileNavFirstDiv {
  position: relative;
  width: 100%;
  height: 35px;
}

#mobileNavFirstDiv div {
  gap: 10px;
}

#mobileNavFirstDiv h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  font-family: Spartan;
  font-size: 24px;
  font-weight: 700;
  color: white;
}

#mobileNavFirstDiv img {
  height: 30px;
  width: 30px;
}

#mobileNavSecondDiv {
  height: 44px;
  width: 100%;
  padding: 0px 4px;
  box-sizing: border-box;
}

#mobileNavThirdDiv {
  background-color: #403d3d;
  height: 30px;
  color: white;
  font-family: Montserrat;
  padding: 0px 10px;
  box-sizing: border-box;
  gap: 5px;
  z-index: 0;
}

#mobileNavThirdDiv h1 {
  font-weight: 500;
  font-size: 18px;
}

.mobileMenuDiv {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #191919;
  width: 280px;
  color: white;
  font-family: Montserrat;
  animation-name: menuSlideInLeft;
  animation-duration: 500ms;
}

.mobileMenuSections {
  background-color: #222222;
  padding: 10px 0px;
  margin-top: 7px;
}

.mobileMenuSectionHeader {
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 15px;
}

.mobileMenuLogoDiv {
  background-color: #b90504;
  font-family: Spartan;
  height: 45px;
}

.mobileMenuLogoDiv h1 {
  font-weight: 800;
}

.mobileMenuOptions {
  color: white;
  height: 50px;
  font-size: 20px;
  font-weight: 300;
  padding: 0px 30px;
  box-sizing: border-box;
  background-color: #222222;
}

@keyframes menuSlideInLeft {
  from {
    left: -280px;
  }
  to {
    left: 0;
  }
}

@keyframes menuSlideOutLeft {
  from {
    left: 0px;
  }
  to {
    left: -280px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  #navOrdersDiv {
    display: none;
  }

  #logoImage {
    left: 0px;
  }

  .navBarSearchBarDiv {
    left: 53%;
  }

  #dropDownDiv {
    right: 70px;
  }
}

@media (max-width: 769px) {
  .navBarSearchBarDiv {
    position: relative;
    left: 0;
    top: 0;
    transform: initial;
    width: 100%;
    min-height: 44px;
    overflow: visible;
  }

  #searchBar {
    height: 44px;
    border-radius: 8px;
    background-color: white;
    font-size: 20px;
    border: 3px solid #333333;
  }

  #searchButton {
    height: 35px;
    width: 35px;
    background-color: #b90504;
    transform: translateX(-5px);
    border-radius: 5px;
  }

  #navDropDownOverlay {
    z-index: 2;
    animation-name: fade-in;
    animation-duration: 500ms;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #regionDropDownDiv {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: calc(100vh - 340px);
    width: 100%;
    overflow: scroll;
    border-radius: 10px 10px 0 0;
    z-index: 1;
    animation-name: regionPopUp;
    animation-duration: 500ms;
  }

  @keyframes regionPopUp {
    from {
      top: 100vh;
    }
    to {
      top: calc(100vh - 340px);
    }
  }

  @keyframes regionPopDown {
    from {
      top: calc(100vh - 340px);
    }
    to {
      top: 100vh;
    }
  }

  #regionSearchBoxDiv input {
    width: 50%;
  }

  #regionDropDownDiv h1 {
    font-size: 20px;
  }

  #regionSearchBoxParentDiv {
    width: 95%;
  }

  #notSignedInRegionDropDownDiv2 {
    width: 95%;
  }

  #notSignedInRegionDropDownDiv2 h1 {
    font-size: 14px;
  }

  #signedInWithoutAddressRegionDropDownDiv2 {
    width: 95%;
  }

  #signedInWithoutAddressRegionDropDownDiv2 h1 {
    font-size: 14px;
  }

  #signedInWithAddressRegionDropDownDiv2 {
    width: 95%;
  }

  #signedInWithAddressRegionDropDownDiv2 h1 {
    font-size: 16px;
  }

  #signedInWithAddressRegionDropDownDiv2 h2 {
    font-size: 16px;
  }

  #regionDropDownDiv::before {
    content: none;
  }

  .regionSearchSuggestionDiv h3 {
    font-size: 20px;
  }
} /* mobile nav bar end */
