.cartContainer {
  position: absolute;
  right: 0px;
  top: 0px;
  max-width: 480px;
  width: 100%;
  background-color: #e8e8e8;
  height: 100%;
  box-sizing: border-box;
  animation-name: cartSlideInRight;
  animation-duration: 500ms;
}

.cartShopContainer {
  padding: 15px 0px;
  width: 100%;
  overflow-y: scroll;
}

.cartTopBar {
  height: 60px;
  background-color: #333333;
  padding: 0px 5px;
  box-sizing: border-box;
}

.cartCloseButton {
  font-size: 50px;
  color: white;
  font-weight: 900;
  padding: 5px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.cartShopCard {
  width: calc(100% - 10px);
  border-radius: 10px;
  background-color: #403d3d;
  padding: 10px 0px 0 0px;
  margin: 0px 5px 15px 5px;
  box-sizing: border-box;
  font-family: Montserrat;
}

.cartShopCardHeader {
  color: white;
  font-weight: 500;
  font-size: 24px;
  margin: 0px 15px 10px 15px;
}

.cartProductCard {
  position: relative;
  background-color: white;
  width: 100%;
  height: 130px;
  gap: 10px;
  box-sizing: border-box;
  margin-bottom: 2px;
}

.cartProductCard h1 {
  font-size: 13px;
  font-weight: 500;
  color: black;
}

.cartProductCard h2 {
  font-size: 15px;
  font-weight: 400;
  margin-top: auto;
  color: black;
}
.cartProductCard span {
  font-weight: 500;
  color: #b90504;
}

.cartProductImageDiv {
  min-width: 130px;
  max-width: 130px;
  height: 100%;
  border-right: 2px solid #403d3d;
}

.cartProductImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.cartProductCardTextDiv {
  padding: 10px 0px;
  box-sizing: border-box;
  max-width: calc(100% - 20px - 130px);
  min-width: calc(100% - 30px - 130px);
}

.cartProductCardQuantityDiv {
  background-color: #b90504;
  border-radius: 20px;
  overflow: hidden;
  min-width: 65px;
  max-width: 65px;
  height: 25px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.cartProductCardQuantityButton {
  width: 33%;
  color: white;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.cartProductCardQuantityButton:hover {
  background-color: #990100;
}

.cartProductCardQuantity {
  width: 34%;
  color: white;
}

.cartShopCardBottomDiv {
  height: 60px;
  background-color: #545454;
  border-top: 3px solid #e8e8e8;
  padding: 0px 8px;
  border-radius: 0px 0px 10px 10px;
}

.cartShopCardBottomDiv h2 {
  color: white;
  font-size: 16px;
  font-weight: 300;
}

.cartShopCardBottomDiv span {
  font-weight: 500;
}

.cartShopCardBottomDivCheckout {
  margin-left: auto;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  overflow: hidden;
  height: 30px;
  padding: 0px 10px;
}

.cartShopCardBottomDivCheckout:hover {
  background-color: #990100;
}

.cartRightDivCheckoutDiv {
  background-color: white;
  border-radius: 20px;
  width: 100%;
  padding: 15px 0px 0px 0px;
  position: relative;
  overflow: hidden;
}

.cartRightDivCheckoutDiv h1 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  margin: 0px 15px;
}

.cartRightDivCheckoutDiv h1 span {
  font-weight: 700;
  color: #b90504;
}

.cartRightDivCheckoutSelectedShops {
  width: calc(100% - 30px);
  background-color: #e8e8e8;
  margin: 20px 0px;
  font-family: Montserrat;
  gap: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 10px;
}

.cartRightDivCheckoutSelectedShops h2 {
  font-size: 15px;
  font-weight: 500;
}

.cartRightDivCheckoutSelectedShops h2 span {
  font-weight: 600;
}

.cartRightDivOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

@keyframes cartSlideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes cartSlideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@media (min-width: 780px) {
  .cartShopContainer::-webkit-scrollbar {
    width: 8px;
  }

  .cartShopContainer::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 4px;
  }
}
