#registerBodyTop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 35vh;
  background-color: #b90504;
  display: flex;
  justify-content: center;
}

#registerBodyTop h1 {
  font-family: Spartan;
  color: white;
  font-weight: 900;
  font-size: 60px;
  margin: 10px 0px;
}

#registerBodyBottom {
  position: fixed;
  top: 35vh;
  width: 100%;
  height: 65vh;
  background-color: #333333;
}

#registerContainerParentDiv {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
}
#registerContainer {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0px);
  background-color: rgb(34, 34, 34);
  width: 450px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
}

#registerContainer h1 {
  font-family: Spartan;
  color: white;
  font-weight: bold;
  font-size: 40px;
  margin: 20px 0px;
  margin-bottom: 40px;
}

#registerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 25px;
}

#registerOTPOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

#registerOTPContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -59%);
  width: 450px;
  height: 220px;
  background-color: rgb(34, 34, 34);
  border: 2px solid white;
  border-radius: 20px;
}

#registerOTPTop {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #b90504;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0 0;
}

#registerOTPForm {
  position: absolute;
  top: 80px;
  height: 140px;
  width: 100%;
}

#registerOTPButtonsDiv {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

#registerResendOTPH2 {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

#registerResendOTPH2:hover {
  font-weight: 500;
}
