.warningTopDiv {
  background-color: rgb(247, 206, 131);
  font-family: Montserrat;
  font-size: 15px;
  transition: height 200ms ease-in;
  font-weight: 500;
}

.warningTopDiv p {
  position: absolute;
  right: 10px;
  font-size: 35px;
  color: dimgray;
  cursor: pointer;
}
