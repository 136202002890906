#searchPageFilterBox {
  position: relative;
  width: 20%;
  border-radius: 20px;
  box-sizing: border-box;
  gap: 15px;
}

.subFiltersDiv {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: Montserrat;
  box-sizing: border-box;
}

.subFiltersHeaderDiv {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 15px;
  border-bottom: 2px solid #e8e8e8;
}

.subFiltersHeader {
  font-size: 20px;
  color: dimgray;
  font-weight: 500;
}

.subFiltersContentDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  box-sizing: border-box;
}

.subFiltersCheckboxDiv {
  gap: 5px;
}

.subFiltersCheckboxDiv label {
  font-size: 15px;
  font-weight: 600;
}

.subFiltersDiv input[type="checkbox"] {
  margin: 5px 0px;
  min-width: 15px;
  min-height: 15px;
}

.subFiltersDiv input[type="checkbox"]::after {
  content: "\2713";
  width: 100%;
  height: 100%;
  background-color: #b90504;
  display: none;
  font-size: 10px;
  color: white;
  font-weight: 900;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 2px;
}

.subFiltersDiv input[type="checkbox"]:checked {
  background-color: #e8e8e8;
  border: none;
}

.subFiltersDiv input[type="checkbox"]:checked::after {
  display: flex;
}

/* mobile styles */

.mobileSearchFilterBoxButton {
  position: sticky;
  bottom: 0px;
  margin-top: auto;
  height: 40px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  z-index: 1;
  border-radius: 10px 10px 0 0;
}

.mobileFilterPopUpDiv {
  position: fixed;
  top: calc(100% - 400px);
  bottom: 0;
  left: 0;
  background-color: #333333;
  width: 100%;
  min-height: 400px;
  border-radius: 10px 10px 0 0;
  animation-name: filtersPopUp;
  animation-duration: 500ms;
}

@keyframes filtersPopUp {
  from {
    top: calc(100% - 40px);
  }

  to {
    top: calc(100% - 400px);
  }
}

@keyframes filtersPopDown {
  from {
    top: calc(100% - 400px);
  }
  to {
    top: calc(100% - 40px);
  }
}

@media (max-width: 780px) {
  .subFiltersDiv {
    margin: 10px 10px;
    width: calc(100% - 20px);
  }
}
