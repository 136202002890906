.paymentContainer {
  position: relative;
  width: calc(100% - 10px);
  max-width: 1000px;
  gap: 10px;
  color: #333333;
}

.paymentLeftDiv {
  width: 67%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.paymentRightDiv {
  position: relative;
  padding: 10px;
  width: 33%;
  background-color: white;
  height: fit-content;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
  gap: 10px;
}

.paymentAddressDiv {
  background-color: #e8e8e8;
  font-family: Montserrat;
  padding: 10px 15px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  gap: 5px;
}

.paymentAddressDiv h1 {
  font-size: 20px;
  font-weight: 700;
}

.paymentAddressDiv p {
  font-size: 15px;
}

.paymentAddressDiv span {
  font-weight: 500;
}

.paymentAddressDiv button {
  margin-top: 10px;
}

.paymentProductsDiv {
  background-color: #e8e8e8;
  margin: 10px 0 0 0;
  border-radius: 10px;
  font-family: Montserrat;
  height: 280px;
  overflow-y: scroll;
}

.paymentProductsDiv::-webkit-scrollbar {
  width: 6px;
}

.paymentProductsDiv::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 3px;
}

.paymentTotalDiv {
  padding: 5px 0px;
  font-weight: 500;
  font-size: 20px;
  border-top: 4px solid white;
}

.paymentProductsCard {
  padding: 9px 10px;
  gap: 20px;
  border-bottom: 1px solid white;
}

.paymentProductTextDiv {
  width: 75%;
}

.paymentProductTextDiv h2 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 3px;
}

.paymentProductTextDiv p {
  font-weight: 500;
  font-size: 14px;
}

.paymentProductPriceDiv {
  text-align: right;
  width: 25%;
}

.paymentProductPriceDiv p {
  font-size: 15px;
  font-weight: 500;
}

.paymentOrderingForDiv {
  width: 100%;
  background-color: #e8e8e8;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Montserrat;
}

.paymentOrderingForDiv p {
  margin: 10px 0 2px 0px;
}

.paymentOrderingForDiv span {
  font-weight: 600;
}

.paymentRightDivHeaders {
  font-size: 19px;
  margin-bottom: 5px;
  font-weight: 600;
}

.paymentRightDivSummary {
  width: 100%;
  background-color: #e8e8e8;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Montserrat;
  gap: 5px;
}

.paymentRightDivOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 25px;
  font-weight: 700;
  font-family: Montserrat;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

@media (max-width: 780px) {
  .paymentContainer {
    flex-direction: column;
    position: relative;
    top: 60px;
  }

  .paymentLeftDiv {
    width: 100%;
  }

  .paymentRightDiv {
    width: 100%;
  }

  .paymentAddressDiv {
    padding: 5px;
  }
}
