#groceryPageMainContainer {
  position: relative;
  left: 50%;
  margin: 20px 0px;
  transform: translateX(-50%);
  max-width: 1200px;
  gap: 10px;
  margin-bottom: 40px;
}

/* css for this page comes from the products page, sorry :) */

@media (max-width: 780px) and (min-width: 481px) {
  #groceryPageMainContainer {
    margin: 10px 0px;
    padding: 0px 10px;
    box-sizing: border-box;
  }
}

@media (max-width: 480px) {
  #groceryPageMainContainer {
    margin: 10px 0px;
  }
}
