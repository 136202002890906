.promotionPageMainContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px 0px;
  max-width: 1200px;
  width: calc(100% - 20px);
  padding: 15px;
  box-sizing: border-box;
  background-color: #333333;
  border-radius: 20px;
}

.promotionPagePromotionBanner {
  width: 100%;
  aspect-ratio: 21/8;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 15px;
}

.promotionPagePromotionBanner img {
  width: 100%;
  max-height: 100%;
}

.promotionPageProductsContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.promotionPageProductCard {
  background-color: white;
  width: 100%;
  height: 200px;
  border-radius: 15px;
  overflow: hidden;
  gap: 10px;
}

.promotionPageProductCardImageDiv {
  width: 200px;
  height: 200px;
  min-width: 200px;
}

.promotionPageProductCardImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.promotionPageProductCardTextDiv {
  font-family: Montserrat;
  padding: 10px 10px 10px 0px;
  color: black;
  gap: 2px;
}

.promotionPageProductCardTextDiv h1 {
  color: #222222;
  font-size: 15px;
  font-weight: 600;
}

.promotionPageProductCardTextDiv h2 {
  color: dimgray;
  font-size: 12px;
  font-weight: 500;
}

.promotionPageProductCardRating {
  width: fit-content;
  padding: 3px 5px;
  margin: 5px 0px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 12px;
}

.promotionPageProductCardRatingDiv {
  gap: 6px;
}

.promotionPageProductCardRatingDiv h4 {
  color: dimgray;
  font-size: 15px;
}

.promotionPageProductCardPriceDiv {
  position: relative;
  background-color: #e8e8e8;
  margin-top: auto;
  max-width: 400px;
  border-radius: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  color: dimgray;
}

.promotionPageProductCardPriceDiv h3 {
  font-weight: 500;
  font-size: 15px;
}

.promotionPageProductCardPriceDiv h2 {
  font-weight: 500;
  font-size: 18px;
}

.promotionPageProductCardPriceDiv h4 {
  font-size: 13px;
  font-weight: 500;
}

.promotionPageProductCardPriceDiv span {
  color: #b90504;
}

@media (max-width: 1024px) {
  .promotionPageProductsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .promotionPageMainContainer {
    width: 100%;
    margin: 0px;
    border-radius: 0;
    padding: 0px 0px 10px 0;
  }

  .promotionPagePromotionBanner {
    border-radius: 0px;
    margin-bottom: 10px;
  }

  .promotionPageProductsContainer {
    gap: 10px;
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .promotionPageProductCard {
    border-radius: 10px;
    height: 180px;
  }

  .promotionPageProductCardTextDiv h1 {
    font-size: 15px;
  }

  .promotionPageProductCardTextDiv h2 {
    font-size: 10px;
  }

  .promotionPageProductCardRating {
    width: fit-content;
    padding: 3px 5px;
    margin: 5px 0px;
    background-color: #b90504;
    color: white;
    font-family: Montserrat;
    font-weight: bolder;
    border-radius: 5px;
    font-size: 10px;
  }

  .promotionPageProductCardRatingDiv {
    gap: 6px;
  }

  .promotionPageProductCardRatingDiv h4 {
    font-size: 13px;
  }

  .promotionPageProductCardPriceDiv {
    border-radius: 10px;
  }

  .promotionPageProductCardPriceDiv h3 {
    font-size: 13px;
  }

  .promotionPageProductCardPriceDiv h2 {
    font-size: 14px;
  }

  .promotionPageProductCardPriceDiv h4 {
    font-size: 12px;
  }

  .promotionPageProductCardImageDiv {
    width: 120px;
    min-width: 130px;
    height: 180px;
  }
}
