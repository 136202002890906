#footer {
  position: relative;
  width: 100%;
  background-color: #333333;
  margin-top: auto;
}

#footerBottom {
  width: 100%;
  height: 45px;
  background-color: #b90504;
}

#footerBottom h1 {
  color: white;
  font-family: Spartan;
  font-weight: bold;
  font-size: 35px;
  margin-top: 5px;
}

#footerLinksDiv {
  padding: 30px 0px;
  gap: 6%;
  width: 90%;
}

.footerColumns {
  max-width: 33%;
}

.footerColumns h2 {
  color: white;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
}

.footerColumns a {
  color: white;
  text-decoration: none;
  font-family: Roboto;
  font-weight: 300;
  font-size: 17px;
}

@media (max-width: 780px) {
  .footerColumns h2 {
    font-size: 15px;
  }

  .footerColumns a {
    font-size: 12px;
  }
}
