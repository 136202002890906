#errorPageBody {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#errorPageContainer {
  position: relative;
  height: 70vh;
  width: 70vw;
  background-color: #333333;
  border-radius: 20px;
}

#errorPageContainer h1 {
  position: relative;
  top: -10vh;
  color: white;
  font-family: Roboto;
  font-size: 27vh;
}

#errorPageContainer h2 {
  position: relative;
  top: -10vh;
  color: white;
  font-size: 5.4vh;
  font-family: Montserrat;
  margin-top: -3vh;
  margin-bottom: 3vh;
}

#errorPageContainer h3 {
  position: relative;
  top: -8vh;
  color: gray;
  font-family: Montserrat;
  font-size: 3vh;
  text-align: center;
}

#errorPageHomeButton {
  position: absolute;
  bottom: 5vh;
}

@media (max-width: 1280px) {
  #errorPageContainer {
    width: 80vw;
  }
}

@media (max-width: 1000px) {
  #errorPageContainer h1 {
    position: relative;
    top: -10vh;
    color: white;
    font-family: Roboto;
    font-size: 22vh;
  }

  #errorPageContainer h2 {
    position: relative;
    top: -10vh;
    color: white;
    font-size: 4vh;
    font-family: Montserrat;
    margin-top: -3vh;
    margin-bottom: 3vh;
  }

  #errorPageContainer h3 {
    position: relative;
    top: -8vh;
    color: gray;
    font-family: Montserrat;
    font-size: 1.5vh;
  }
}

@media (max-width: 480px) {
  #errorPageContainer {
    width: 90vw;
    top: 25px;
  }
}
