#homePageMainContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  padding: 0px 25px;
  margin: 25px 0px 0px 0px;
  box-sizing: border-box;
}

#offerSwiperContainerDiv {
  width: 100%;
  gap: 15px;
}

.offerSwiper {
  width: 100%;
  aspect-ratio: 21/7;
  border-radius: 17px;
  margin: 15px 0px;
}

.shopOfferSwiper {
  width: 100%;
  aspect-ratio: 21/8;
  border-radius: 20px;
}

.offerSwiper img {
  width: 100%;
  height: 100%;
}

.shopOfferImageDiv {
  width: 100%;
  height: 100%;
  display: block;
}

.shopOfferImageDiv img {
  width: 100%;
  height: 100%;
}

.shopOfferImageDiv > .componentLoadingDiv img {
  width: 15%;
  aspect-ratio: 1/1;
}

.horizontalSwiper {
  position: relative;
  left: 50%;
  width: calc(100vw - 18px);
  transform: translateX(-50%);
  padding: 0px 10px 30px 10px;
  margin: 0 0 -20px 0;
  box-sizing: border-box;
}

.homePageProductCards {
  position: relative;
  background-color: white;
  border-radius: 20px;
  font-family: Montserrat;
  padding: 5px;
  gap: 10px;
  text-align: center;
  color: black;
}

.homePageProductCards h2 {
  font-size: 16px;
  font-weight: 600;
  height: 34px;
  line-height: 17px;
}

.homePageProductImageDiv {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  aspect-ratio: 1/1;
}

.homePageProductImageDiv img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.homePageProductPriceDiv {
  background-color: #e8e8e8;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 15px;
}

.homePageProductPriceDiv span {
  font-size: 15px;
  font-weight: 500;
  color: #b90504;
  text-decoration: line-through;
}

.homePageProductPriceDiv p {
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  color: #b90504;
}

.componentContainer {
  margin: 0 0 20px 0;
  padding: 15px 0px;
  border-radius: 2vh;
}

.componentContainer h1 {
  font-family: Montserrat;
  margin: -5px 0px 20px 10px;
  font-size: 25px;
  color: #383838;
}

.offersAndShopContainer {
  width: 100%;
  background-color: #333333;
  border-radius: 20px;
  margin: 0px 0px 25px 0px;
  padding: 25px;
  box-sizing: border-box;
}

.offersAndShopContainer h1 {
  color: white;
  font-family: Montserrat;
  margin: 30px 0 20px 10px;
  font-size: 30px;
  text-align: center;
}

.shopNearbyCard {
  aspect-ratio: 16/9;
  border-radius: 20px;
  overflow: hidden;
  background-image: url(https://cdn.discordapp.com/attachments/220769489833164810/909109183117402132/the-sun-music-palm-trees-background-wallpaper-preview.png);
  background-size: 100%;
}

.shopNearbyCardOverlayDiv {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  font-family: Montserrat;
  color: white;
  padding: 10px 15px;
  box-sizing: border-box;
  gap: 5px;
  transition: all 100ms ease-in;
}

.shopNearbyCardOverlayDiv:hover {
  background-color: transparent;
}

.shopNearbyCardOverlayDiv h2 {
  font-size: 26px;
}

.shopNearbyCardOverlayDiv h4 {
  font-size: 20px;
  font-weight: 400;
  margin-top: auto;
}

.shopNearbyCardRatingDiv {
  gap: 10px;
}

.shopNearbyCardRating {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px;
  background-color: #b90504;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 15px;
}

.shopNearbyCardDistanceDiv {
  gap: 2px;
  margin-top: 10px;
}

.shopNearbyCardDistanceDiv h5 {
  font-weight: 500;
  font-size: 17px;
}

.homePageCategoryGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 25px;
}

.homePageCategoryCard {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.homePageCategoryImageDiv {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: white;
  border-radius: 20px;
}

.homePageCategoryImageDiv img {
  width: 100%;
}

.homePageCategoryNameDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: Montserrat;
  background-color: rgba(185, 5, 4, 0.8);
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  color: white;
}

.homePageCategoryNameDiv p {
  font-weight: 600;
}

.homePageNoShopsNearbyDiv {
  position: relative;
  width: 100%;
  aspect-ratio: 21/6;
  color: white;
  background-color: #444444;
  border-radius: 20px;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 800;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}

.homePageNoShopsNearbyDiv > .componentLoadingDiv img {
  width: 15%;
  aspect-ratio: 1/1;
}

@media (max-width: 1023px) {
  #homePageMainContainer {
    margin-top: 0px;
    padding: 0px;
  }

  .offerSwiper {
    border-radius: 10px;
  }

  .shopOfferSwiper {
    border-radius: 10px;
  }

  .homePageProductCards {
    border-radius: 10px;
  }

  .horizontalSwiper {
    width: 100vw;
  }

  .offersAndShopContainer {
    border-radius: 10px;
    padding: 10px 8px;
  }

  .offersAndShopContainer h1 {
    font-size: 17px;
    font-weight: 600;
  }

  .shopNearbyCard {
    border-radius: 10px;
  }

  .homePageCategoryGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .homePageCategoryCard {
    border-radius: 10px;
  }

  .homePageCategoryImageDiv {
    border-radius: 10px;
  }

  .componentContainer {
    padding: 0px;
  }

  .componentContainer h1 {
    font-size: 16px;
    margin: 5px 0px 15px 10px;
  }

  .shopNearbyCardOverlayDiv {
    padding: 5px 7px;
  }

  .shopNearbyCardOverlayDiv h2 {
    font-size: 15px;
  }

  .shopNearbyCardOverlayDiv h4 {
    display: none;
  }

  .shopNearbyCardRatingDiv {
    gap: 5px;
  }

  .shopNearbyCardRating {
    font-size: 10px;
  }

  .shopNearbyCardRatingDiv h3 {
    font-size: 12px;
  }

  .shopNearbyCardDistanceDiv {
    gap: 2px;
    margin-top: auto;
  }

  .shopNearbyCardDistanceDiv h5 {
    font-size: 14px;
  }

  .shopNearbyCardDistanceDiv img {
    height: 14px;
    width: 14px;
  }

  .homePageNoShopsNearbyDiv {
    border-radius: 10px;
  }
}

@media (max-width: 780px) {
  .homePageCategoryGrid {
    gap: 10px;
    padding: 0px 10px;
    box-sizing: border-box;
  }
  .homePageNoShopsNearbyDiv {
    font-size: 22px;
  }

  .homePageProductPriceDiv {
    border-radius: 7px;
  }

  .homePageProductPriceDiv p {
    font-size: 17px;
  }

  .homePageProductPriceDiv span {
    font-size: 15px;
  }

  .homePageProductCards h2 {
    font-size: 14px;
    height: 31px;
    line-height: 15.5px;
  }

  .offerSwiper {
    margin: 0 0 15px 0;
    border-radius: 0;
  }

  .offersAndShopContainer {
    border-radius: 0px;
    padding: 0px 0 15px 0;
  }

  .offersAndShopContainer h1 {
    margin: 15px 10px 10px 10px;
    text-align: center;
    font-size: 18px;
  }

  .shopOfferSwiper {
    border-radius: 0px;
  }

  .shopsNearbySwiper {
    width: 100%;
    padding: 0px 7px;
    box-sizing: border-box;
  }

  .homePageNoShopsNearbyDiv {
    margin: 0px 7px;
    width: calc(100% - 10px);
  }
}

@media (max-width: 759px) {
  .homePageCategoryGrid {
    grid-template-columns: 1fr 1fr;
    gap: 7px;
    padding: 0px 7px;
  }

  .homePageNoShopsNearbyDiv {
    font-size: 15px;
  }

  .homePageProductPriceDiv {
    border-radius: 5px;
  }

  .homePageProductPriceDiv p {
    font-size: 15px;
  }

  .homePageProductPriceDiv span {
    font-size: 11px;
  }

  .homePageProductCards h2 {
    font-size: 10px;
    height: 25px;
    line-height: 12.5px;
  }
}
