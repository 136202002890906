#productPageMainContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  margin: 20px 0px;
  display: flex;
  max-width: 1200px;
  gap: 10px;
  margin-bottom: 10px;
}

.productPageReviewMainContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  max-width: 1200px;
  gap: 10px;
  margin-bottom: 20px;
}

#productLeftDiv {
  width: 30%;
  position: sticky;
  height: fit-content;
  top: 10px;
  gap: 10px;
}

.productLeftDivImageDiv {
  position: relative;
  width: 100%;
  min-height: 360px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.productLeftDivImageDiv img {
  max-width: 100%;
  max-height: 480px;
}

.productLeftDivOtherImagesDiv {
  position: absolute;
  top: 5px;
  left: -60px;
  gap: 5px;
}

.productLeftDivOtherImagesDiv div {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  background-color: white;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  opacity: 0.8;
}

.productLeftDivOtherImagesDiv .activeImage {
  border: 2px solid #b90504;
  opacity: 1;
}

.productLeftDivOtherImagesDiv div img {
  max-height: 100%;
  max-width: 100%;
}

#productsMiddleDiv {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
}

#productsRightDiv {
  width: 25%;
  height: fit-content;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: sticky;
  top: 10px;
}

#selectedShopProductRightDiv {
  position: relative;
  background-color: white;
  width: 100%;
  padding: 1px 0px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
}

.productMiddleDivSections {
  position: relative;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.productMiddleDivSectionHeader {
  font-family: Montserrat;
  color: dimgray;
  font-size: 20px;
  margin: 5px 15px;
  margin-top: 14px;
}

.productMiddleDivProductName {
  margin: 15px 20px;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
}

.productMiddleDivSectionPriceDiv {
  background-color: #e8e8e8;
  margin: 15px 15px;
  margin-top: 30px;
  padding: 10px 10px;
  border-radius: 10px;
}

.productMiddleDivSectionPriceDiv h3 {
  font-family: Montserrat;
  color: dimgray;
  font-size: 16.5px;
  font-weight: 500;
}

.productMiddleDivSectionPriceDiv h3 span {
  color: #b90504;
  text-decoration: line-through;
}

.productMiddleDivSectionPriceDiv h4 {
  font-family: Montserrat;
  color: dimgray;
  font-size: 15px;
  font-weight: 500;
}

.productMiddleDivSectionPriceDiv h4 span {
  color: #b90504;
}

.productMiddleDivSectionPrice {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  color: dimgray;
  margin-bottom: 5px;
}

.productMiddleDivSectionPrice span {
  font-weight: 700;
  color: #b90504;
}

.productMiddleDivRatingDiv {
  margin: 0 0 0 20px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.productMiddleDivRatingDiv h1 {
  margin: 0px;
  font-size: 17px;
  color: #555555;
  font-family: Montserrat;
}

.productMiddleDivRating {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 13px;
}

.productMiddleDivDescription {
  color: #333333;
  margin: 10px 15px;
  margin-bottom: 15px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 15px;
}

.productMiddleDivSpecsCategoryDiv {
  background-color: #e8e8e8;
  margin: 10px 15px;
  padding: 1px 0px;
  border-radius: 10px;
}

.productMiddleDivSpecsHeader {
  color: black;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 9px 10px;
  margin-bottom: 10px;
}

.productMiddleDivSpecsFields {
  display: flex;
  gap: 20px;
  font-family: Montserrat;
  margin: 5px 0px;
  padding: 5px 10px;
  border-top: 2px solid white;
}

.productMiddleDivSpecsFields h1 {
  width: 30%;
  font-weight: 300;
  font-size: 14px;
}

.productMiddleDivSpecsFields h2 {
  width: 70%;
  font-weight: 500;
  font-size: 14px;
}

.selectedShopDetailDiv {
  position: relative;
  background-image: url(https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1476&q=80);
  background-size: 100%;
  border-radius: 10px;
  height: fit-content;
  height: 130px;
  overflow: hidden;
  margin: 10px 15px;
}

.selectedShopDetailOverlayDiv {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  padding: 1px 0px;
}

.selectedShopDetailOverlayDiv:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.selectedShopDetailOverlayDiv h1 {
  color: white;
  font-family: Montserrat;
  font-size: 20px;
  margin: 0px 10px;
  margin-top: 4px;
}

.selectedShopDetailOverlayDiv h2 {
  color: white;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: 15px;
  margin-left: 10px;
}

.productRightDivRatingDiv {
  gap: 10px;
  margin: 5px 0px;
}

.productRightDivRating {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px;
  margin-left: 10px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 11px;
}

.productRightDivRatingDiv h1 {
  font-size: 15px;
  color: white;
  font-family: Montserrat;
  margin: 0px 0px;
}

.mapMarkerHoverDiv {
  position: relative;
  background-size: 100%;
  height: 100px;
  width: 200px;
  border-radius: 7px;
  overflow: hidden;
}

.mapPopUpOverlayDiv {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  padding: 1px 0px;
}

.mapPopUpOverlayDiv:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.mapPopUpOverlayDiv h1 {
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  margin: 0px 10px;
  margin-top: 4px;
}

.mapPopUpOverlayDiv h2 {
  color: white;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: 15px;
}

.mapPopUpRatingDiv {
  gap: 10px;
  margin: 5px 0px;
}

.mapPopUpRating {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 11px;
}

.mapboxgl-popup-content {
  padding: 5px !important;
  border-radius: 10px !important;
}

#productRightDivButtons {
  gap: 5px;
  margin-bottom: 10px;
  padding: 0px 15px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.productPageReviewContainer {
  width: 100%;
  box-sizing: border-box;
}

.productPageAddReviewContainer {
  width: 100%;
  height: 100px;
  padding: 0px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: sticky;
  top: 10px;
  overflow: hidden;
}

.productReviewCard {
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  font-family: Montserrat;
  gap: 10px;
  border: 2px solid #e8e8e8;
}

.productReviewCard h1 {
  font-size: 15px;
  margin-left: auto;
}

.productReviewCard p {
  font-size: 16px;
  display: flex;
  padding: 10px 15px;
  background-color: #e8e8e8;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.productReviewCard span {
  color: dimgray;
  font-weight: 500;
}

.productReviewCard div:nth-child(1) {
  gap: 10px;
}

.productReviewCard .productMiddleDivRating {
  font-size: 17px;
  flex-shrink: 0;
}

@media (max-width: 1200px) and (min-width: 481px) {
  #productPageMainContainer {
    margin: 10px 0px;
    padding: 0px 10px;
    box-sizing: border-box;
    max-width: 780px;
  }

  .productPageReviewMainContainer {
    padding: 0px 10px;
    box-sizing: border-box;
  }

  #productsRightDiv {
    width: 40%;
  }

  #productsMiddleDiv {
    width: 60%;
  }

  #productLeftDiv {
    position: relative;
    top: 0;
    width: 100%;
    border-radius: 10px;
  }

  #selectedShopProductRightDiv {
    border-radius: 10px;
  }

  .productMiddleDivSections {
    border-radius: 10px;
  }

  .productMiddleDivRatingDiv {
    margin: 0 0 10px 20px;
  }

  .productMiddleDivProductName {
    font-size: 19px;
  }

  .productReviewCard p {
    font-size: 16px;
  }

  .productReviewCard div:nth-child(1) {
    flex-direction: column;
  }

  .productReviewCard {
    border-radius: 9px;
  }
}

@media (max-width: 780px) {
  .productLeftDivOtherImagesDiv {
    top: auto;
    left: 0px;
    bottom: 10px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  #productPageMainContainer {
    margin: 10px 0px;
  }

  .productPageReviewMainContainer {
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .productReviewCard p {
    font-size: 16px;
  }

  .productReviewCard div:nth-child(1) {
    flex-direction: column;
  }

  #productsMiddleDiv {
    margin: 0px 5px;
    width: calc(100% - 10px);
  }

  .productMiddleDivSections {
    border-radius: 10px;
  }

  .productMiddleDivProductName {
    margin: 10px 15px;
    font-size: 18px;
  }

  .productMiddleDivRatingDiv {
    margin: 0 0 10px 15px;
  }

  #productLeftDiv {
    position: relative;
    top: 0;
    width: 100%;
    border-radius: 10px;
  }

  #productsRightDiv {
    width: 100%;
    position: relative;
    top: 0px;
  }

  #selectedShopProductRightDiv {
    border-radius: 10px;
  }

  .selectedShopDetailDiv {
    height: auto;
    aspect-ratio: 2/1;
  }

  .selectedShopDetailOverlayDiv h1 {
    font-size: 24px;
  }

  .selectedShopDetailOverlayDiv h2 {
    font-size: 18px;
  }

  .productRightDivRatingDiv h1 {
    font-size: 15px;
  }

  .productMiddleDivSpecsCategoryDiv {
    margin: 10px;
  }

  .productReviewCard {
    border-radius: 9px;
  }
}
