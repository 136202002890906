#addAddressMainDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 320px;
  width: 700px;
  border-radius: 10px;
}

#addAddressFormDiv {
  position: relative;
  width: 385px;
  height: 100%;
  background-color: #333333;
  border-radius: 10px 0px 0px 10px;
}

#addAddressFormDiv h1 {
  margin: 20px 0 0 calc(15% - 15px);
  color: white;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 24px;
}

#addAddressMapDiv {
  width: 315px;
  height: 320px;
  background-color: #e8e8e8;
  border-radius: 0px 10px 10px 0px;
  overflow: hidden;
}

#addAddressInactiveInput {
  margin: 20px 0 0 calc(15% - 17px);
  width: 70%;
  height: 40px;
  border-radius: 10px;
  padding: 0px 15px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  color: white;
  border: 2px solid white;
}

#addAddressForm {
  margin: 15px 0 0 0;
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#addAddressButtonDiv {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

#addAddressMapButton {
  position: absolute;
  top: 8px;
  right: 45px;
  height: 33px;
  background-color: white;
  border: 2px solid rgb(214, 214, 214);
  border-radius: 5px;
  padding: 0px 5px;
  cursor: pointer;
}

#addAddressMapButton:hover {
  background-color: rgb(247, 247, 247);
}

@media (max-width: 700px) {
  #addAddressMainDiv {
    position: absolute;
    top: auto;
    transform: translate(-50%, 0);
    bottom: 0px;
    max-width: 100%;
    height: calc(100% - 119px);
    flex-direction: column-reverse;
  }

  #addAddressFormDiv {
    width: 100%;
    height: fit-content;
    background-color: #333333;
    border-radius: 0;
  }

  #addAddressMapDiv {
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
  }

  #addAddressInactiveInput {
    margin-left: calc(10% - 15px);
    width: 80%;
  }

  #addAddressFormDiv .animatedInputInputs {
    width: 80%;
  }

  #addAddressFormDiv .animatedInputLabelsSmallerLighterGray {
    left: 10%;
  }

  #addAddressFormDiv .animatedInputLabelsLighterGray {
    left: 10%;
  }

  #addAddressFormDiv h1 {
    margin-left: calc(10% - 15px);
  }
}
