.addReviewsShopInfo {
  margin: 50px 0 10px 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #e8e8e8;
  border-radius: 10px;
}

.addReviewsShopInfo h1 {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
}
