#shopDetailsMainContainer {
  margin: 20px 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1100px;
  gap: 12px;
}

.infoCardParentDiv {
  width: 28%;
  gap: 10px;
  height: fit-content;
  position: sticky;
  top: 10px;
}

.infoCard {
  position: relative;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.shopInfoImageDiv {
  background-image: url(https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1476&q=80);
  background-size: 100%;
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
}

.shopImageOverlayDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  gap: 10px;
  align-items: flex-end;
  padding-bottom: 10px;
  box-sizing: border-box;
  transition: background-color 200ms ease-in;
  font-size: 10px;
  color: white;
  font-family: Montserrat;
}

.infoCard:hover .shopImageOverlayDiv {
  background-color: rgba(0, 0, 0, 0);
}

.shopRatingDiv {
  width: fit-content;
  height: fit-content;
  padding: 3px 5px;
  margin-left: 10px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 18px;
}

.shopInfoTextDiv {
  font-family: Montserrat;
  padding: 20px 15px;
  width: 100%;
  color: #222222;
  box-sizing: border-box;
}

.shopInfoTextDiv h1 {
  font-size: 23px;
}

.shopInfoTextDiv h2 {
  color: #333333;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

.shopInfoTextDiv h3 {
  font-weight: 700;
  margin-top: 60px;
  font-size: 20px;
}

#rightDivContainer {
  width: 72%;
  border-radius: 20px;
  position: relative;
}

#shopInventoryTools {
  background-color: #b90504;
  height: 55px;
  width: 100%;
  border-radius: 27.5px;
}

.shopDetailsInventorySearch {
  background-color: #e8e8e8;
  border: none;
  height: 40px;
  border-radius: 20px;
  margin-right: 7px;
  padding: 0px 17px;
  width: 250px;
  font-size: 18px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-weight: 600;
}

.shopDetailsInventorySearch:focus {
  outline: none;
}

#shopInventoryToolsForm {
  width: fit-content;
  margin-left: auto;
}

#shopDetailsCategorySelect {
  margin-right: 5px;
}

.shopDetailsInventoryCards {
  background-color: white;
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  gap: 15px;
  position: relative;
}

#shopDetailsInventoryImage {
  min-width: 200px;
  max-width: 200px;
  height: 100%;
}

#shopDetailsInventoryImage img {
  max-width: 100%;
  max-height: 100%;
}

#shopDetailsInventoryTextDiv {
  font-family: Montserrat;
  padding: 15px 5px 10px 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

#shopDetailsInventoryTextDiv h1 {
  color: #222222;
  font-size: 16px;
}

#shopDetailsInventoryTextDiv:hover#shopDetailsInventoryTextDiv h1 {
  color: #b90504;
}

.shopDetailsInventoryCardParentDiv {
  border-radius: 20px;
  margin: 12px 0px;
}

#shopDetailsProductTypeDiv {
  margin-left: 7px;
  height: 40px;
  gap: 5px;
  z-index: 2;
}

#shopDetailsProductTypeDiv div {
  border-radius: 20px;
  font-family: Montserrat;
  font-size: 12px;
  color: white;
  padding: 0px 20px;
  height: 100%;
  cursor: pointer;
}

#shopDetailsProductTypeDiv div h1 {
  font-weight: 700;
}

#shopDetailsProductTypeDiv div:hover {
  background-color: #990100;
}

.shopDetailsTabActive {
  background-color: white !important;
  color: #b90504 !important;
}

.shopDetailsInventoryPriceDiv {
  background-color: #e8e8e8;
  height: 90px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  padding-left: 15px;
  color: dimgray;
  margin-right: 10px;
}

.shopDetailsInventoryPriceDiv h3 {
  font-weight: 500;
  font-size: 16.5px;
}

.shopDetailsInventoryPriceDiv h2 {
  font-weight: 500;
  font-size: 21px;
}

.shopDetailsInventoryPriceDiv h4 {
  font-weight: 400;
  font-size: 15px;
  margin-top: 5px;
}

.shopDetailsInventoryPriceDiv span {
  color: #b90504;
}

.shopDetailsInventoryButtonsDiv {
  height: 90px;
  min-width: 220px;
  margin: 0px 20px 0 auto;
  gap: 5px;
  z-index: 2;
}

.shopDetailsInventoryNoProductsDiv {
  position: relative;
  background: white;
  width: 100%;
  height: 200px;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  color: dimgray;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#shopDetailsReviewContainer {
  position: relative;
  background: white;
  width: 100%;
  margin: 10px 0 0 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: Montserrat;
  overflow: hidden;
}

#shopDetailsReviewContainer h1 {
  font-family: Montserrat;
  color: dimgray;
  font-size: 20px;
  margin: 5px 15px;
  margin-top: 14px;
}

.shopReviewCard {
  background-color: white;
  border-radius: 9px;
  padding: 10px;
  margin: 5px;
  box-sizing: border-box;
  font-family: Montserrat;
  gap: 10px;
  border: 2px solid #e8e8e8;
}

.shopReviewCard h2 {
  font-size: 15px;
  margin-left: auto;
}

.shopReviewCard p {
  font-size: 16px;
  display: flex;
  padding: 10px 15px;
  background-color: #e8e8e8;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.shopReviewCard span {
  color: dimgray;
  font-weight: 500;
}

.shopReviewCard div:nth-child(1) {
  gap: 10px;
}

.shopReviewCard .productMiddleDivRating {
  font-size: 17px;
  flex-shrink: 0;
}

@media (max-width: 780px) {
  #shopDetailsMainContainer {
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .infoCardParentDiv {
    width: 100%;
    position: relative;
    top: 0;
  }

  #rightDivContainer {
    width: 100%;
  }

  #shopInventoryTools {
    height: fit-content;
    padding: 7px 7px;
    box-sizing: border-box;
    gap: 10px;
    overflow: hidden;
    border-radius: 15px;
  }

  #shopInventoryToolsForm {
    width: 100%;
  }

  .shopDetailsInventorySearch {
    width: 100%;
    margin: 0;
    font-size: 20px;
    border-radius: 10px;
  }

  #shopDetailsProductTypeDiv {
    width: 100%;
    margin: 0;
  }

  #shopDetailsProductTypeDiv div {
    padding: 0px;
    width: 50%;
    border-radius: 10px;
  }

  #shopDetailsProductTypeDiv div:nth-last-of-type(1) {
    margin-left: auto;
  }

  .shopInfoTextDiv {
    padding: 10px 15px 20px 15px;
  }

  .shopInfoTextDiv h1 {
    font-size: 26px;
  }

  .shopInfoTextDiv h2 {
    margin-top: 10px;
  }

  .shopInfoTextDiv h3 {
    font-size: 21px;
  }

  .shopDetailsInventoryCards {
    height: 170px;
    border-radius: 10px;
  }

  #shopDetailsInventoryImage {
    min-width: 110px;
    max-width: 110px;
    height: 100%;
  }

  #shopDetailsInventoryTextDiv h1 {
    font-size: 14px;
  }

  .shopDetailsInventoryPriceDiv {
    min-height: 60px;
    height: auto;
  }

  .shopDetailsInventoryPriceDiv h2 {
    font-size: 16px;
  }

  .shopDetailsInventoryPriceDiv h3 {
    font-size: 13px;
  }

  .shopDetailsInventoryPriceDiv h4 {
    font-size: 14px;
  }

  #shopDetailsInventoryTextDiv .groceryTag {
    font-size: 12px;
    padding: 3px 6px;
  }
}

@media (max-width: 480px) {
  .shopReviewCard div:nth-child(1) {
    flex-direction: column;
  }
}
