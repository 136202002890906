.addReviewsContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 10px);
  max-width: 600px;
  background-color: white;
  border-radius: 20px;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
}

.addReviewsProductInfo {
  margin: 50px 0 10px 0;
  width: 100%;
  height: 80px;
  background-color: #e8e8e8;
  border-radius: 10px;
}

.addReviewsProductInfo h1 {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
}

.addReviewsProductImageDiv {
  height: 80px;
  min-width: 80px;
  max-width: 80px;
  padding: 5px 0px;
  box-sizing: border-box;
}

.addReviewsProductImageDiv img {
  max-height: 100%;
  min-height: 100%;
}

.addReviewsForm {
  width: 100%;
  margin: 10px 0px;
  gap: 10px;
}

.addReviewsForm input[type="range"] {
  -webkit-appearance: none;
  width: 80%;
  height: 10px;
  background-color: #e8e8e8;
  border-radius: 10px;
}

.addReviewsForm input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #990100;
}

.addReviewsSetReviewDiv {
  padding: 3px 7px;
  background: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 5px;
}

.addReviewsForm textarea {
  width: 100%;
  height: 150px;
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: #e8e8e8;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 500;
}
