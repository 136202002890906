#loginBodyTop {
  width: 100%;
  height: 35vh;
  background-color: #b90504;
  display: flex;
  justify-content: center;
}

#loginBodyTop h1 {
  font-family: Spartan;
  color: white;
  font-weight: 900;
  font-size: 60px;
  margin: 35px 0px;
}

#loginBodyBottom {
  position: absolute;
  top: 35vh;
  width: 100%;
  height: 65vh;
  background-color: #333333;
}

#loginContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  height: 280px;
  background-color: rgb(34, 34, 34);
  border-radius: 20px;
}

#loginForm {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 35px;
}

#loginForm button {
  border: none;
  background-color: #b90504;
  color: white;
  height: 40px;
  padding: 0px 10px;
  border-radius: 20px;
  font-family: Montserrat;
  font-weight: bold;
  cursor: pointer;
}

#goToLoginAndRegister {
  color: white;
  text-decoration: none;
  font-family: Montserrat;
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
