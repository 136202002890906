#productSearchPageMainContainer {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0px);
  max-width: 1400px;
  margin: 30px 0px;
  padding: 0px 20px;
  box-sizing: border-box;
  gap: 15px;
}

#rightContainer {
  width: 80%;
}

#productSearchPageFilterBar {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b90504;
  border-radius: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0px 20px;
  box-sizing: border-box;
}

#searchResultsContainer {
  transform: translateY(1vw);
  width: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.searchResultCards {
  position: relative;
  height: 210px;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  gap: 10px;
  overflow: hidden;
  padding-right: 10px;
  box-sizing: border-box;
}

#searchResultCardImageDiv {
  min-width: 210px;
  max-width: 210px;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
}

#searchResultCardImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.searchResultCardTextDiv {
  font-family: Montserrat;
  padding: 10px 0px;
  width: calc(100% - 220px);
  height: 100%;
  box-sizing: border-box;
  gap: 2px;
}

.searchResultCardTextDiv h1 {
  color: #222222;
  font-size: 15px;
  font-weight: 600;
}

.searchResultCardTextDiv h2 {
  color: dimgray;
  font-size: 12px;
  font-weight: 500;
}

.searchResultCardTextDiv:hover h1 {
  color: #b90504;
}

.searchResultCardPriceDiv {
  position: relative;
  background-color: #e8e8e8;
  margin-top: auto;
  max-width: 400px;
  border-radius: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  color: dimgray;
}

.searchResultCardPriceDiv h3 {
  font-weight: 500;
  font-size: 15px;
}

.searchResultCardPriceDiv h2 {
  font-weight: 500;
  font-size: 18px;
}

.searchResultCardPriceDiv h4 {
  font-size: 13px;
  font-weight: 500;
}

.searchResultCardPriceDiv span {
  color: #b90504;
}

.searchResultCardRating {
  width: fit-content;
  padding: 3px 5px;
  margin: 5px 0px;
  background-color: #b90504;
  color: white;
  font-family: Montserrat;
  font-weight: bolder;
  border-radius: 5px;
  font-size: 12px;
}

.searchResultCardRatingDiv {
  gap: 6px;
}

.searchResultCardRatingDiv h4 {
  color: dimgray;
  font-size: 15px;
}

/* failed div styling */
#productsNoResultsDiv {
  font-family: Montserrat;
  gap: 10px;
  width: 80%;
  text-align: center;
}

#productsNoResultsDiv a {
  margin: 10px 0 0 0;
}

@media (max-width: 1200px) {
  #searchResultsContainer {
    grid-template-columns: 1fr;
  }
}

/* mobile css */
@media (max-width: 780px) {
  #productSearchPageMainContainer {
    margin: 10px 0px 0 0;
    padding: 0px;
    gap: 10px;
    min-height: 72vh;
  }

  #searchResultsContainer {
    padding: 0px 5px;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr;
  }

  .searchResultCards {
    padding-right: 10px;
    border-radius: 10px;
    height: 180px;
  }

  #searchResultCardImageDiv {
    min-width: 120px;
    max-width: 120px;
  }

  .searchResultCardTextDiv {
    padding: 5px 0 10px 0px;
    width: calc(100% - 130px);
  }

  .searchResultCardTextDiv h1 {
    font-size: 15px;
  }

  .searchResultCardTextDiv h2 {
    font-size: 10px;
  }

  .searchResultCardRating {
    width: fit-content;
    padding: 3px 5px;
    margin: 5px 0px;
    background-color: #b90504;
    color: white;
    font-family: Montserrat;
    font-weight: bolder;
    border-radius: 5px;
    font-size: 10px;
  }

  .searchResultCardRatingDiv {
    gap: 6px;
  }

  .searchResultCardRatingDiv h4 {
    font-size: 13px;
  }

  .searchResultCardPriceDiv {
    border-radius: 10px;
  }

  .searchResultCardPriceDiv h3 {
    font-size: 13px;
  }

  .searchResultCardPriceDiv h2 {
    font-size: 14px;
  }

  .searchResultCardPriceDiv h4 {
    font-size: 12px;
  }

  #productSearchPageFilterBar {
    width: calc(100% - 10px);
    height: 50px;
    border-radius: 15px;
    margin: 0px 5px;
    padding: 0px 10px;
  }

  #productSearchPageFilterBar label {
    font-size: 13px;
  }

  #productSearchPageFilterBar select {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  #searchResultsContainer {
    grid-template-columns: 1fr;
  }
}
