.userOrderPageMainContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px 0px;
  max-width: 940px;
  padding: 0px 5px;
  box-sizing: border-box;
}

.userOrdersPageTopBar h1 {
  color: dimgray;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 28px;
  margin: 0 0 18px 10px;
}

.userOrdersCard {
  background-color: #333333;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
  height: fit-content;
}

.userOrdersInfoTopBar {
  background-color: #333333;
  height: 70px;
  border-radius: 20px 20px 0 0;
  padding: 0px 10px;
  box-sizing: border-box;
  font-family: Montserrat;
  color: white;
  margin-bottom: 10px;
  border-bottom: 2px solid #e8e8e8;
}

.userOrdersInfoDiv {
  height: 100%;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.userOrdersDeliveryStatusTag {
  height: 34px;
  border-radius: 17px;
  padding: 0px 20px;
  margin-left: auto;
  font-weight: 600;
}

.userOrdersDeliveryStatusTag.Pending {
  background-color: rgb(255, 191, 0);
}

.userOrdersDeliveryStatusTag.On-Route {
  background-color: rgb(80, 168, 255);
}

.userOrdersDeliveryStatusTag.Completed {
  background-color: green;
}

.userOrdersDeliveryStatusTag.Cancelled {
  background-color: #b90504;
}

.userOrderProductCard {
  background-color: white;
  height: 130px;
  border-radius: 10px;
  margin: 0px 10px 10px 10px;
  overflow: hidden;
  gap: 10px;
}

.userOrderProductImageDiv {
  height: 100%;
  min-width: 130px;
  max-width: 130px;
}

.userOrderProductImageDiv img {
  max-width: 100%;
  max-height: 100%;
}

.userOrderProductTextDiv {
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #333333;
  width: 100%;
  gap: 5px;
}

.userOrderProductTextDiv h1 {
  font-size: 16px;
  font-weight: 600;
}

.userOrderProductTextDiv:hover h1 {
  color: #b90504;
}

.userOrderProductTextDiv h3 {
  color: dimgray;
  font-weight: 400;
  font-size: 15px;
}

.userOrderProductTextDiv h2 {
  font-size: 18px;
  font-weight: 600;
  margin-top: auto;
}

.userOrderProductTextDiv h2 span {
  color: #b90504;
}

.userOrderDeclineTag {
  font-family: Montserrat;
  background-color: #b90504;
  color: white;
  font-size: 15px;
  font-weight: 600;
  height: 30px;
  padding: 0px 10px;
  border-radius: 15px;
  margin: 5px;
  min-width: fit-content;
}

.userOrdersBottomBar {
  height: 60px;
  border-top: 2px solid #e8e8e8;
  padding: 0px 10px;
}

.userOrdersBottomBar .redButton {
  font-weight: 400;
  font-size: 20px;
}

.ordersPageNoOrdersDiv {
  height: 140px;
  background-color: white;
  color: dimgray;
  border-radius: 20px;
  text-align: center;
  font-family: Montserrat;
  gap: 5px;
}

.ordersPageNoOrdersDiv h1 {
  font-weight: 700;
  font-size: 30px;
}

@media (max-width: 500px) {
  .userOrdersCard {
    border-radius: 10px;
  }

  .userOrdersInfoDiv {
    margin-left: 5px;
    font-size: 18px;
  }

  .userOrderProductCard {
    margin: 0 5px 10px 5px;
    width: calc(100% - 10px);
  }

  .userOrderProductImageDiv {
    min-width: 90px;
    max-width: 90px;
  }

  .userOrderProductTextDiv h1 {
    font-size: 14px;
  }

  .userOrderProductTextDiv h2 {
    font-size: 15px;
  }

  .userOrderProductTextDiv h3 {
    font-size: 13px;
  }

  .userOrdersInfoTopBar {
    border-radius: 10px 10px 0 0;
    height: 60px;
  }

  .ordersPageNoOrdersDiv {
    height: 100px;
    border-radius: 10px;
  }

  .ordersPageNoOrdersDiv h1 {
    font-size: 25px;
  }
}
