#smallNavDiv {
  position: relative;
  top: 0;
  left: 0;
  background-color: #333333;
  z-index: 2;
  height: 60px;
  width: 100%;
}

#topNav {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
}

#smallLogoImageDiv {
  position: absolute;
  top: 0%;
  left: 40px;
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  background-color: #b90504;
}

#smallLogoImage {
  width: 45px;
  height: 45px;
  z-index: 1;
  cursor: pointer;
  margin-top: -5px;
}

@media (max-width: 1024px) {
  #smallLogoImageDiv {
    left: 0;
  }
}
